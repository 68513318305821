import React from "react";
import PropTypes from "prop-types";

const createScriptElement = () => {
  let commands =
    "window.dataLayer = window.dataLayer || [];" +
    "function gtag(){dataLayer.push(arguments);}" +
    "gtag('js', new Date());" +
    "gtag('config', 'UA-59804174-1');";
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = commands;
  document.head.appendChild(script);
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(script, s.nextSibling);
};

class AnalyticsTagContainer extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
    if (this.props.enable) {
      createScriptElement();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // For initial load
  componentDidMount() {
    this._isMounted = true;
    if (this.props.enable) {
      this.setState({
        index: this.state.index + 1
      });
    }
  }

  render() {
    return <></>;
  }
}

AnalyticsTagContainer.defaultProps = {
  enable: true
};

AnalyticsTagContainer.propTypes = {
  enable: PropTypes.bool
};

export default AnalyticsTagContainer;
