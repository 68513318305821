import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["jaxwsWebserviceUsingCXF"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "10"
};

const data = [
  {
    type: "subheading",
    name: "jaxws-webservice-using-cxf",
    title: "WebService"
  },
  {
    type: "text",
    content:
      "SOAP(Simple Object Access Protocol) is a protocol to send XML messages over HTTP. JAX-WS is the standard that defines how to create a SOAP based webservices. " +
      "Let us look at a simple example on how to create a simple JAX-WS SOAP webservice using CXF and Spring. The following software has been used for this tutorial : " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "1. Apache Maven 3.0.4<br /> " +
      "2. Spring 3.2.6<br /> " +
      "3. Apache CXF 3.0.3<br /> " +
      "4. Jetty 6.1.10/Tomcat 7 " +
      "<div class='lineBreak' /> " +
      "First, we need to create a basic CXF web project structure. For this, use the below command : " +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>mvn archetype:generate -DgroupId=com.learninjava -DartifactId=cxfjaxws <br /> " +
      "-DarchetypeArtifactId=org.apache.cxf.archetype:cxf-jaxws-javafirst -DinteractiveMode=false</code> " +
      "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. If the archetype is not found due to future maven changes, you can simply use the below command : " +
          "<div class='lineBreakSmall' /> " +
          "<code>mvn archetype:generate</code> " +
          "<div class='lineBreakSmall' /> " +
          "Now, select the appropriate archetype serial number from the list provided. On our development environment, the serial number for our tutorial is, " +
          "<div class='lineBreakSmall' /> " +
          "<i>515: remote -> org.apache.cxf.archetype:cxf-jaxws-javafirst (Creates a project for developing a Web service starting from Java code)</i> " +
          "<div class='lineBreakSmall' /> " +
          "2. You can actually create a restful webservice without even writing a single line of code. " +
          "<div class='lineBreakSmall' /> " +
          "See here : " +
          "<a class='homelink' href='http://java-hub.blogspot.in/2015/02/jax-ws-soap-webservice-using-cxf-and.html' target='_blank'>JAX-WS-webservice-using-cxf-and-maven</a>" +
          "<div class='lineBreakSmall' />" +
          "Remember, you will just get an unpolished version of the project with out concepts being explained. So, we recommend you going through the below tutorial."
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "If the command is successful, your basic project structure should look similar to the below : " +
      "<div class='lineBreak' /> " +
      "<img src='https://github.com/learninjavagithub/assets/raw/master/jaxws-webservice-project-structure.jpg' alt='jaxws-webservice-project-structure' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "The maven surefire plugin expects the test file names in the following patterns : " +
          "<div class='lineBreakSmall' /> " +
          "**/Test*.java<br /> " +
          "**/*Test.java<br /> " +
          "**/*TestCase.java<br /> " +
          "<div class='lineBreakSmall' /> "
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "There were many changes made to pom.xml that comes default with this archetype. " +
      "For instance, we have removed some unused configurations and refactored the configurations for easy and better understanding." +
      "<div class='lineBreak' /> " +
      "For your convenience, we have provided configurations for running the webservice on both Jetty and Tomcat servers." +
      "<div class='lineBreak' /> " +
      "First, let us take a look at tomcat configuration. Later we will see about jetty configuration. There is a cool feature in jetty that attracts many developers. we will see what is it later in this tutorial."
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "using-tomcat",
    title: "Using Tomcat :"
  },
  {
    type: "text",
    content: "<h4>1. Change the default pom.xml as below :</h4>"
  },
  {
    type: "gist",
    content: "ff6079be52b30ee90141115a518f9789"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You will notice that there are 3 important plugins in the pom.xml out of which 1 is optional. We will see each of them in detail : " +
      "<div class='lineBreakSmall' /> " +
      "<b>tomcat7-maven-plugin</b> - This is the tomcat plugin where we will deploy our angry world webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-compiler-plugin</b> - This plugin specifies the Java compiler source and target versions." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-eclipse-plugin</b> - [Optional] This plugin is used to generate the .classpath and .project files which enables us to use this project in eclipse." +
      "<div class='lineBreakSmall' /> " +
      "The other important sections includes dependencies and properties which are used to specify the jar file dependencies and properties respectively." +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>2. Renamed HelloWorld.java to HelloAngryWorld.java :</h4>"
  },
  {
    type: "gist",
    content: "4876901bfdd89607578766cbe5f4b96b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is the actual webservice source file, so lets take a look at it." +
      "<div class='lineBreakSmall' /> " +
      "This webservice exposes only one method <code>sayHi()</code> which returns a String type. This method uses an important annotation: " +
      "<div class='lineBreakSmall' /> " +
      "<b>@WebService</b> - annotation at class level specifies that this is the webservice endpoint interface." +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content:
      "<h4>3. Renamed HelloWorldImpl.java to HelloAngryWorldImpl.java :</h4>"
  },
  {
    type: "gist",
    content: "832983582f32100c43e172652fa3b29e"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>4. beans.xml :</h4>"
  },
  {
    type: "gist",
    content: "062c04b4a56c085d495eee07a896ed2d"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The beans.xml has an important tag <code>&lt;jaxws:endpoint&gt;</code> which is the most important tag of this xml." +
      "<div class='lineBreakSmall' />" +
      "<b>&lt;jaxws:endpoint&gt;</b> - This lists all the service beans which are nothing but the services we want to make as a webservice" +
      "<div class='lineBreakSmall' />" +
      "Observe that names of the entities like endpoint, implementor etc. These are in conjunction with SOAP protocol. You will find these names to be different when dealing with JAX-RS."
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>5. web.xml :</h4>"
  },
  {
    type: "gist",
    content: "500064e749f7542ad57cfef02294bd96"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The configurations in web.xml are straighforward. CXFServlet is the actual servlet that reads the xml configurations and makes the service beans as webservices and handles the requests and responses to the exposed services." +
      "<div class='lineBreakSmall' />" +
      "ContextLoaderListener is the ROOT web application context that is used by spring." +
      "<div class='lineBreakSmall' />" +
      "Once the above files are ready, type the below command to start the tomcat container :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true tomcat7:run</code>" +
      "<div class='lineBreakSmall' />" +
      "This command cleans the target directory, compiles, builds war and deploys it to tomcat. Notice that we used skipTests = true switch. This is to make sure that the test case are not run before the server starts." +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:9090/jaxws-service/HelloAngryWorld?wsdl</i>"
  },
  {
    type: "output",
    content: "\rOutput is the below WSDL\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "04f01bc7c6dce93d221c78dbbd1102a8"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "This is an echo service. You need a SOAP client to create a request message to test this webservice." +
      "<div class='lineBreakSmall' />" +
      "If you are unware of your endpoint address, you can use the below url to find out," +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:9090/jaxws-service/cxf/services</i>" +
      "<div class='lineBreakSmall' />" +
      "This lists all the REST and SOAP services running on your server."
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "subheading",
    name: "using-jetty",
    title: "Using Jetty :"
  },
  {
    type: "text",
    content: "<h4>1. pom.xml :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The pom.xml is pretty much similar to the one we have used for tomcat configuration except that the tomcat plugin is replaced with the jetty equivalent. Also the port number is changed 8080 just to distinguish between tomcat and jetty."
  },
  {
    type: "gist",
    content: "0e8ccd5158b644db9e2cf922d9be65a7"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "All other steps are same as above." +
      "<div class='lineBreakSmall' />" +
      "To start the jetty container and the webservice use the below command :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true jetty:run</code>" +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:8080/jaxws-service/HelloAngryWorld?wsdl</i>"
  },
  {
    type: "output",
    content: "\rSame WSDL as above\r\n"
  },
  {
    type: "subheading",
    name: "advantages-of-using-jetty",
    title: "Advantage of using Jetty - Hot code deployment :"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "By default, Jetty scans the following directories :" +
      "<div class='lineBreakSmall' />" +
      "<b>target/classes</b> - for changes to Java sources and" +
      "<div class='lineBreakSmall' />" +
      "<b>src/main/webapp</b> - for changes to web sources." +
      "<div class='lineBreakSmall' />" +
      "If any source file is modified, the changes are autodeployed to the jetty container without manually restarting for the changes to take effect." +
      "<div class='lineBreakSmall' />" +
      "To test this hot code deployment, try to change any source file and recompile the source using <code>mvn compile</code> command." +
      "<div class='lineBreakSmall' />" +
      "The moment the source file is compiled, notice the server console. You will observe that jetty hot deploys the code automatically and you can access the changes via browser instantly."
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL:
      "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    previousText:
      "3 ways to convert SOAPMessage to Java Object with namespace and XML to SOAPMessage",
    nextURL: "/jaxws-soap-client-using-cxf/",
    nextText: "JAX-WS SOAP Client using CXF"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/cxfjaxws.zip"
  }
];

class SOAPWebserviceUsingCXF extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default SOAPWebserviceUsingCXF;
