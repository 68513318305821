import React from "react";
import { hydrate, render } from "react-dom";
import { withRouter, BrowserRouter, Route } from "react-router-dom";
import App from "App.js";

import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Route path="/" component={withRouter(App)} />
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <Route path="/" component={withRouter(App)} />
    </BrowserRouter>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
