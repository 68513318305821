import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["soapMessageToObject"],
  ...allHashTags,
  channel: "20"
};

const data = [
  {
    type: "subheading",
    name: "convert-soapmessage-to-java-object",
    title: "Convert SOAPMessage to Java Object"
  },
  {
    type: "text",
    content:
      "Ever thought what is the hardest part in dealing with SOAP messages ? You are right, " +
      "Converting to and from SOAPMessage to Java Object and vice versa, especially when " +
      "you get the SOAPMessage in a String object. " +
      "<div class='lineBreak' /> " +
      "Converting SOAPMessages to Java Object's is no longer a complex task once you read through " +
      "this post. You might have seen tons of articles on this topic but ever seen the examples used ? Yes!! they are " +
      "simple XML messages with no attributes, no namespaces, no namespace prefixes. We call these messages as 'Hello World XMLs'. " +
      "In real world, your XMLs are not so simple." +
      "<div class='lineBreak' /> " +
      "We promise, we wont be dealing with 'Hello World XMLs'. We will explain the converting using an XML message with attributes, namespaces, namespace prefix, header with namespace, " +
      "namespace prefix etc."
  },
  {
    type: "text",
    content: "<h4>Our Test XML, complex isn't it ?</h4>"
  },
  {
    type: "gist",
    content: "8815956e4f32d23de4e26bf4b79abc6d"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 1: SOAPMessage to Java Object Using Jaxb2Marshaller</h3>"
  },
  {
    type: "text",
    content: "<h4>1. Configure Jaxb2Marshaller Bean</h4>"
  },
  {
    type: "gist",
    content: "0bd4cb7054e4b78644dfff8f06377267"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>2. Autowire Jaxb2Marshaller Bean</h4>"
  },
  {
    type: "gist",
    content: "e9f18e4ea102906ac8cf761dee4b91ef"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>3. Convert to SOAPMessage</h4>"
  },
  {
    type: "text",
    content:
      "This is the one of the most important steps, Observe, how the namespace prefix and namespace URIs are " +
      "used in both the header and the body. This is very useful when you want to add some tag to the header. " +
      "Ideally, most of the financial institutions and secure systems uses this kind of headers."
  },
  {
    type: "gist",
    content: "e15e9e1c89003f7d6ff32cc8d3cb73e8"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>4. UnMarshall to Java Object</h4>"
  },
  {
    type: "gist",
    content: "03bfab6fc60daa2bb7ae42a434142a19"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>5. Response Java Object</h4>"
  },
  {
    type: "text",
    content:
      "This is the most important steps, if you make any mistake here, you will lose a lot of time debugging the different kinds of exceptions. So be very careful while defining Response object."
  },
  {
    type: "gist",
    content: "88701ace72e7d3050db59f25ed1a25bd"
  },
  {
    type: "output",
    content:
      "\r--- Print SOAPMessage as String --- " +
      '\r&lt;?xml version="1.0" encoding="UTF-8" standalone="no"?>' +
      '\r&lt;SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:lij="http://www.learninjava.com/soaptoobject/1_0/" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
      "\r      &lt;SOAP-ENV:Header>" +
      '\r&nbsp;&lt;lijcommon:LIJHeader xmlns:lijcommon="http://www.learninjava.com/common/1_0" MsgID="bf82a0fc-0f08-464f-8fed-ac8c60f3a1ce" Timestamp="Sun Aug 23 21:49:23 CDT 2020" Version="1.0"/>' +
      "\r&lt;/SOAP-ENV:Header>" +
      "\r&lt;SOAP-ENV:Body>" +
      "\r        &lt;lij:Response>" +
      '\r        &nbsp;&lt;lij:Country Code="1" Continent="NA" Name="US">' +
      '\r              &nbsp;&nbsp;&lt;lij:State Code="713" Name="Texas" StateCode="TX"/>' +
      '\r              &nbsp;&nbsp;&lt;lij:State Code="678" Name="Georgia" StateCode="GA"/>' +
      '\r              &nbsp;&nbsp;&lt;lij:State Code="480" Name="Arizona" StateCode="AZ"/>' +
      "\r          &nbsp;&lt;/lij:Country>" +
      '\r          &nbsp;&lt;lij:Demographics Capital="Washington" Currency="Dollar" Language="English"/>' +
      "\r        &lt;/lij:Response>" +
      "\r      &lt;/SOAP-ENV:Body>" +
      "\r&lt;/SOAP-ENV:Envelope>" +
      "\r\n" +
      "\r--- Using Jaxb2Marshaller Object ---" +
      "\rElement's Attribute: US" +
      "\rSub Element List's Attribute: Georgia" +
      "\rAnother Element's Attribute: Washington\r"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. Be careful while defining Response object. Use <b>@XmlRootElement</b> only once at the root element only " +
          "<div class='lineBreakSmall' /> " +
          "2. Attributes should be annotated with <b>@XmlAttribute</b> and Element should annotated with <b>@XmlElement</b> annotations" +
          "<div class='lineBreakSmall' /> " +
          "3. Use namespace prefix and namespace URIs at the right places"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />You can find source of the working application at the end. Check &quot;Download Source&quot; section."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 2: SOAPMessage to Java Object Using XMLStreamReader</h3>"
  },
  {
    type: "text",
    content: "<h4>1. Convert SOAPMessage to Java Object</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "5a1ed31149abdcc152a45e948a84b782"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>2. Unmarshall using XMLStreamReader</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "ad0d96567131ae73eaccb27e2a98eb17"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 3: SOAPMessage to Java Object Using Document</h3>"
  },
  {
    type: "text",
    content: "<h4>1. Convert SOAPMessage to Java Object</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "f3b6c8908782f279affa4a6dd471569b"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>2. Unmarshall using Document</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "87fbfcefee5757ab05938036132cb9ac"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Bonus Utilities</h3>"
  },
  {
    type: "text",
    content: "<h4>1. Get Document object from SOAPMessage as String</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "de2127f3f84b58863eb7179b0e77e290"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "74c585ea1c5263fdfdd1acfe462c38e1"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "<h4>2. Get String from SOAPMessage using Stream</h4>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "7909c912e9f68084a749bea4dbabb2ab"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "2dba66df3fe982bdc5492da72b418244"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/jaxws-soap-client-using-cxf/",
    previousText: "JAX-WS SOAP Client using CXF",
    nextURL: "/jaxws-soap-webservice-using-cxf/",
    nextText: "JAX-WS SOAP WebService using CXF"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/SOAPToObjectWithNamespace.zip"
  }
];

class SOAPMessageToObject extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default SOAPMessageToObject;
