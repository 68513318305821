import React from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "components/CustomWidgets/ErrorBoundary";

class EmailSubscriptionContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const {
      props: { path }
    } = this;
    return nextProps.path != path;
  }

  // For Refresh
  componentDidUpdate() {
    this.createScriptElement();
  }

  // For initial load
  componentDidMount() {
    this.createScriptElement();
  }

  createScriptElement() {
    if (this.props.enable) {
      const script = document.createElement("script");
      // prettier-ignore
      script.src = "https://app.mailjet.com/statics/js/iframeResizer.min.js";
      script.type = "text/javascript";

      document.body.appendChild(script);
    }
  }

  mailJetScript() {
    return '<iframe class="mj-w-res-iframe" style="min-height: 375px !important;" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/5Ifx/Ett" width="100%"></iframe>';
  }

  render() {
    return (
      <React.Fragment>
        <ErrorBoundary>
          {this.props.enable ? (
            <div
              dangerouslySetInnerHTML={{
                __html: this.mailJetScript()
              }}
            ></div>
          ) : (
            ""
          )}
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

EmailSubscriptionContainer.defaultProps = {
  enable: true
};

EmailSubscriptionContainer.propTypes = {
  enable: PropTypes.bool,
  path: PropTypes.object
};

export default EmailSubscriptionContainer;
