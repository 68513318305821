import React from "react";
import PropTypes from "prop-types";

class OutputBox extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, content, ...rest } = this.props;
    return (
      <div style={{ marginTop: "50px" }}>
        <b>{title}</b>
        <br />
        <div
          {...rest}
          dangerouslySetInnerHTML={{
            __html: content.replace(/[\r\n]/g, "<br />")
          }}
        />
      </div>
    );
  }
}

OutputBox.defaultProps = {
  title: "Output:"
};

OutputBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default OutputBox;
