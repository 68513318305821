import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["stream"],
  ...allHashTags,
  channel: "3"
};

const data = [
  {
    type: "subheading",
    name: "streams",
    title: "Why Stream ?"
  },
  {
    type: "text",
    content:
      "Java 8 has introduced a new concept called Stream. These " +
      "are a group of computations specified as a sequence of " +
      "steps. Although, the name sounds " +
      "similar to Java IO API classes like " +
      "InputStream/OutputStream, this has nothing to do with " +
      "them. This is a famous feature in functional programming. " +
      "Java 8 has adopted this feature of functional programming " +
      "and named it as Stream. " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Streams can work on various input sources like Arrays, " +
      "Collections etc. A Stream can also be created using a list " +
      "of objects using Stream interface. This supports various " +
      "data types. We can also create primitive data type streams " +
      "using interfaces IntStream, LongStream and DoubleStream. " +
      "<div class='lineBreak' /> " +
      "The below example illustrates some of the possible ways a " +
      "Stream can be created and manipulated: " +
      "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "8f330305e7e324aca00ab360ecfb44c0"
  },
  {
    type: "output",
    content:
      "BLUE BIRD\rRED BIRD\rYELLOW BIRD\r\n\rRed Bird" +
      "\rYellow Bird\rBlue Bird\r\n\rBlue Bird\rRed Bird\rYellow Bird" +
      "\r\n\rYellow Bird\rBlue Bird\r\n\r6\r6000000\r7.8\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "In the example above, Observe how we can apply all functions on the " +
      "streams using simple commands." +
      "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "One important thing to note in the example is the usage of forEach. " +
          "<i> forEach </i> is not a method of List but still we can use it as if " +
          "its a part of the List interface. How is this possible ? This is " +
          "possible because forEach is a default method in List. This is the " +
          "realtime usage of a default method in Collections API." +
          "<div class='lineBreak' /> " +
          "If you open up the source file of Iterable interface you will see " +
          "forEach method declared as default."
      },
      {
        type: "gist",
        content: "d9bba0dbaa4826725b301aae8836225a"
      },
      {
        type: "text",
        content:
          "<div class='lineBreak' /> " +
          "Note that List interface extends Collection interface and Collection" +
          "interface extends Iterable. This is how forEach works in List."
      }
    ]
  },
  {
    type: "subheading",
    name: "collect-flatmap-reduce-parallelstream",
    title: "Stream - Collect, FlatMap, Reduce and ParallelStream"
  },
  {
    type: "text",
    content:
      "Let us see some advanced stream functions. Most important of them all are below : " +
      "<div class='lineBreakSmall' /> " +
      "<b>Collect</b> - This is used to collect the results into a collection i.e, List, Set or a Map." +
      "<div class='lineBreakSmall' /> " +
      "<b>FlatMap</b> - A map() function is used to apply a function say toUpperCase and transform the " +
      "existing stream however, it does not return the output as multiple streams. The flatMap() is used " +
      "to do exactly this, i.e, it returns multiple streams from a single stream." +
      "<div class='lineBreakSmall' /> " +
      "<b>Reduce</b> - Reduce as the name suggests combines multiple elements and reduces it to a single " +
      "result." +
      "<div class='lineBreakSmall' /> " +
      "<b>ParallelStream</b> - The parallelStream() method is used to run the steps/streams in parallel. " +
      "Imagine this as if you are running the methods in multithreaded environment with a pool of threads. "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "By default the number of threads in this pool is system dependent. To use a custom pool size, " +
          "use the below JVM argument when running the below example," +
          "<div class='lineBreakSmall' /> " +
          "<i>-Djava.util.concurrent.ForkJoinPool.common.parallelism=5</i>"
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The below example illustrates all of the above functions with examples for each type:"
  },
  {
    type: "gist",
    content: "03e7921e4839beeb5fc23c1943ceb55d"
  },
  {
    type: "output",
    content:
      "Actual List : [Red Bird, Yellow Bird, Blue Bird, Yellow Bird]\rCollect example using " +
      "toSet : [RED BIRD, YELLOW BIRD, BLUE BIRD]\rCollect example using toList : [Blue Bird, " +
      "Black Bird]\rCollect example using toMap : {1=BLUE, 2=RED, 3=BLACK}\rUsing flatMap() : " +
      "1\rUsing flatMap() : 2\rUsing flatMap() : 1\rUsing flatMap() : 2\rUsing flatMap() : " +
      "1\rUsing flatMap() : 2\rreduce() using Objects : BLACK\rreduce() using primitivies : " +
      "17\rParallel Streams default pool size : 3\rThread Name in filter : main\rThread Name in " +
      "map : main\rRED BIRD7\rThread Name in filter : main\rThread Name in map : main\rRED " +
      "BIRD6\rThread Name in filter : main\rThread Name in map : main\rRED BIRD9\rThread Name " +
      "in filter : main\rThread Name in map : main\rRED BIRD10\rThread Name in filter : main\r" +
      "Thread Name in map : main\rRED BIRD8\rThread Name in filter : ForkJoinPool.commonPool-worker-1\r" +
      "Thread Name in filter : ForkJoinPool.commonPool-worker-2\rThread Name in map : " +
      "ForkJoinPool.commonPool-worker-2\rRED BIRD2\rThread Name in filter : " +
      "ForkJoinPool.commonPool-worker-2\rThread Name in map : ForkJoinPool.commonPool-worker-2\r" +
      "RED BIRD1\rThread Name in filter : ForkJoinPool.commonPool-worker-2\rThread Name in map : " +
      "ForkJoinPool.commonPool-worker-2\rRED BIRD5\rThread Name in filter : " +
      "ForkJoinPool.commonPool-worker-2\rThread Name in map : ForkJoinPool.commonPool-worker-2\r" +
      "RED BIRD4\rThread Name in map : ForkJoinPool.commonPool-worker-1\rRED BIRD3\r\n"
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "As you can see from the output that in case of parallel streams, each of the steps are " +
      "executed by either of the three threads namely ForkJoinPool.commonPool-worker-1, " +
      "ForkJoinPool.commonPool-worker-2 and main thread. Yes, even the main thread is used as " +
      "a thread inside the pool. All the other examples are self-explanatory from their output." +
      "<div class='lineBreak' /> " +
      "Stream in Java 8 is a vast topic and the above quick examples should make give you a quick " +
      "overview of the new functionality."
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/java8-lambda-expressions/",
    previousText: "Java 8 - Lambda Expressions",
    nextURL: "/java8-functional-interfaces/",
    nextText: "Java 8 - Functional Interface"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/java8-stream.zip"
  }
];

const Stream = () => {
  return (
    <>
      <Article header={header} data={data} />
    </>
  );
};

export default Stream;
