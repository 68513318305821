import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["easyGuideToSSL"],
  ...allHashTags,
  channel: "28"
};

const data = [
  {
    type: "toc",
    title: "SSL Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> Easy Guide to SSL - All the terms you need to know - [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/convert-p12-to-jks-and-pem-to-jks' target='_blank'>" +
      "Convert PKCS#12 to JKS and PEM to JKS format</MyLink></h4>" +
      "<h4><b>Part 3 : </b> <MyLink class='homelink' href='/tls-mutual-auth-two-way-ssl-postman-soapui' target='_blank'>" +
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "terminology-age-old-confusion",
    title: "Terminology - Age old confusion"
  },
  {
    type: "text",
    content:
      "Are you confused with the different terms like <code style='white-space: normal'>Certificate</code>, " +
      "<code style='white-space: normal'>Certificate Chain</code>, <code style='white-space: normal'>KeyStore</code>, <code style='white-space: normal'>TrustStore</code> and different " +
      "formats like <code style='white-space: normal'>PKCS#12</code> and <code style='white-space: normal'>PEM</code> ? Searching for these jargon confused even more ? Dont worry, after reading through " +
      "this article, you will never need to search again." +
      "<div class='lineBreak' /> " +
      "Let us understand what each of the above terms are. Think about an analogy of a student graduating from a college. " +
      "In some countries, some colleges are affiliated to a university. When a student graduates from his college, they are " +
      "provided with a graduation certificate. This certificate acts as an artifact that other instituations can trust when student " +
      "continues with further studies." +
      "<div class='lineBreak' /> " +
      "The college needs to be certified by a university and hence maintains a certificate in its office. These certificates are often " +
      "inspected by university authorities." +
      "<div class='lineBreak' /> " +
      "Finally, the university is controlled by a government education department and imagine it needs to be certified by the department. " +
      "<div class='lineBreak' /> " +
      "This analogy is pretty similar to what we have in SSL communication. The student certificate is termed here as <code style='white-space: normal'>Client Certificate</code>. " +
      "The certificate that the college has is termed as <code style='white-space: normal'>Intermediate Certificate</code>. In SSL world, there can be multiple colleges. So multiple " +
      "Intermediate Certificates are possible." +
      "<div class='lineBreak' /> " +
      "The certificate that the university gets from the government department is the <code style='white-space: normal'>Root Certificate</code>. " +
      "The entire chain of certificates from university to student is termed as <code style='white-space: normal'>Certificate Chain</code>."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "keystore-truststore",
    title: "KeyStore Vs TrustStore"
  },
  {
    type: "text",
    content:
      "To understand the difference between a KeyStore and a TrustStore, first understand what is a Store. A <code style='white-space: normal'>Store</code> is an archive much like a safe " +
      "to store all the security artifacts like a <code style='white-space: normal'>Certificate</code>, " +
      "<code style='white-space: normal'>Public/Private Key</code> or a <code style='white-space: normal'>Chain of certificates</code> or all of them."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/keystore-truststore.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "A <code style='white-space: normal'>KeyStore</code> basically has all artifacts required to talk to a server. Often, servers ask for these artifacts before talking or before <b>trusting the client</b>." +
      "<div class='lineBreak' /> " +
      "A <code style='white-space: normal'>TrustStore</code> has artifacts for a client to trust a server before talking to the server or before <b>trusting the server</b>. " +
      "These are only conceptually different although we can use a single store for both."
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "certificate-formats-PKCS#12-PFX-PEM",
    title: "Certificate Formats - PKCS#12, PFX, PEM, JKS etc"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>PKCS#12(.p12)/PEM(.pem)</code> files can contain a certificate, certificate request, public key, private key or all of the above." +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>Public Key Cryptography Standards(PKCS)</code> are binary files. These are the newer format of <code style='white-space: normal'>.pfx</code> files. #12 " +
      "is the 12th version of the standard." +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>Privacy Enhanced Mail(PEM)</code> are text file alternative to PKCS format. Since its not easy to transfer the binary files over all media like an email for instance, " +
      "there was a need to manipulate security artifacts in an easier way. This is why the PEM format gained popularity." +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>Java Key Store(JKS)</code> are binary files similar to PKCS#12. Created for java based systems."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<code style='white-space: normal'>.pem</code> is a generic file extension. It is recommended to use <code style='white-space: normal'>.cer</code>, <code style='white-space: normal'>.crt</code> for " +
          "a certificate and <code style='white-space: normal'>.key</code> for public or private keys, although we can use <code style='white-space: normal'>.pem</code> for all the artifacts."
      }
    ]
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>Sample .p12 file</h4>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/sample-p12.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>Sample .pem file</h4>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/sample-pem.jpg"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/tls-mutual-auth-two-way-ssl-postman-soapui/",
    previousText:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    nextURL: "/convert-p12-to-jks-and-pem-to-jks/",
    nextText: "Convert PKCS#12 to JKS and PEM to JKS format"
  }
];

class EasyGuideToSSL extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default EasyGuideToSSL;
