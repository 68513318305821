import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

class CustomPagination extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  setActivePage(pageNo) {
    this.setState({
      activePage: pageNo
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.onRef(undefined);
  }

  handlePageChange(pageNumber) {
    if (this._isMounted) {
      this.setState({ activePage: pageNumber });
    }
    this.props.fetchData(pageNumber);
    window.scrollTo(0, 0);
  }

  render() {
    const { limit, totalItemsCount } = this.props;
    return (
      <Pagination
        ref={reactPaginate => (this.reactPaginateRef = reactPaginate)}
        prevPageText="<"
        nextPageText=">"
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange.bind(this)}
      />
    );
  }
}

CustomPagination.defaultProps = {
  limit: 5
};

CustomPagination.propTypes = {
  fetchData: PropTypes.func,
  limit: PropTypes.number,
  totalItemsCount: PropTypes.number,
  onRef: PropTypes.any
};

export default CustomPagination;
