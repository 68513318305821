import React from "react";
import ReactWidgetPackComments from "react-widgetpack-comments";

import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";

class Contact extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="header-bar"></div>
        <div
          className="container-fluid"
          style={{
            minHeight: "650px",
            maxWidth: "500px",
            position: "relative"
          }}
        >
          <div
            className="row"
            style={{
              textAlign: "center"
            }}
          >
            <div>
              <div>
                <h1 style={{ color: "#ef6c00" }}>Why Learninjava ?</h1>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="displayTextLight">
                This website is created to help developers with the painful
                things that they face daily. Often solutions on the internet are
                either partial or erroneous. This usually frustrates the
                developers and often have to put extra effort to make the
                solutions work. Sometimes, the solutions are often missing the
                vital information which results in no working product.
                <div className="lineBreak" /> We often faced these challenges
                during our development experience and thought of providing
                painless solutions so that developers get access to working
                content and saves their daily time. This is the very reason why
                this website has been created. We tried our best to provide all
                the content free and working. All the articles, blogs and posts
                on this website accompanies downloadble working source, ready to
                work on.
                <div className="lineBreak" />
                The information provided on this website is free to read, modify
                or share. There are no boundaries or licenses in any form. All
                we expect from you is to spread the knowledge. Remember, if you
                have learned something here, you can tell others about the same.
                If you are a website owner, we expect you to include our website
                name and link so that others can benefit from you.
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h3>Disclaimer</h3>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="displayTextLight">
                No birds (Angry Birds) or animals (Pigs) were harmed during the
                making of this website or its content. All the birds and animal
                characters used in some articles of this website are fictitious.
                Any resemblance to real birds/animals, living or dead, is purely
                coincidental :)
                <div className="lineBreakSmall" />
                All trademarks and logos belongs to their owners.
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>Contact us at:</h4>
                <h5>
                  <a
                    className="homelink"
                    href="mailto:contactus@learninjava.com"
                  >
                    contactus@learninjava.com
                  </a>
                </h5>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>Like, share or comment at: </h4>
              </div>
              <div className="col-md-12">
                <div className="solidlineLight"></div>
              </div>
              <div className="col-md-12">
                <SocialMediaIcons color="themed" type="square" size={48} />
              </div>
              <div className="col-md-12">
                <div className="solidlineLightBottom"></div>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>
                  If you like this website, support by buying us a coffee,
                  <a target="blank" href="https://ko-fi.com/learninjava">
                    <img src="img/ko-fi.gif" alt="Buy-me-a-coffee" />
                  </a>
                </h4>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>You can also share us your thoughts below: </h4>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">
                <ReactWidgetPackComments id={22435} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
