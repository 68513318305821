import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["lambdaExpressions"],
  ...allHashTags,
  channel: "2"
};

const data = [
  {
    type: "subheading",
    name: "lambda-expressions",
    title: "Java 8 - Lambda Expressions"
  },
  {
    type: "text",
    content:
      "To understand Lambda Expressions, we first need to understand what a " +
      "<MyLink class='homelink' href='/java8-functional-interfaces' target='_blank'>Functional Interfaces</MyLink>" +
      " is ? Lambda Expressions treats functionality as method arguments or code as data. Very difficult definition to understand ? " +
      "For Me too!! Simply said, lambda expressions simplifies the syntax when we have only one method in an interface which needs to be called. No more weird anonymous inner class syntax."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "The lambda expressions can be specified in 3 different styles: " +
          "<div class='lineBreakSmall' />" +
          "1. ([DataType] parameter(s)) -> statement" +
          "<div class='lineBreakSmall' />" +
          "For example, " +
          "<div class='lineBreakSmall' />" +
          "<code>e -> System.out.println(&quot;Using flatMap() : &quot; + e.size);</code>" +
          "<div class='lineBreakSmall' />" +
          "2. ([DataType] parameter(s)) -> { statements } " +
          "<div class='lineBreakSmall' />" +
          "For example, " +
          "<div class='lineBreakSmall' />" +
          "<code>b -> {" +
          "<div class='lineBreakSmall' />" +
          "System.out.println(&quot;Thread Name in map : &quot; + Thread.currentThread().getName());" +
          "<div class='lineBreakSmall' />" +
          "return b.toUpperCase();" +
          "<div class='lineBreakSmall' />" +
          "}</code>" +
          "<div class='lineBreakSmall' />" +
          "3. ([DataType] parameter(s)) -> expression" +
          "<div class='lineBreakSmall' />" +
          "For example," +
          "<div class='lineBreakSmall' />" +
          "<code>(b1, b2) -> b1 + b2;</code>" +
          "<div class='lineBreakSmall' />" +
          "For more examples, see <MyLink class='homelink' href='/java8-stream' target='_blank'>Stream</MyLink> examples." +
          "<div class='lineBreakSmall' />" +
          "The <b>DataType</b> is an optional field and can be ommitted."
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> For instance, consider the below example,"
  },
  {
    type: "gist",
    content: "2f4b695ef87eea1069adcb4ffaeb7349"
  },
  {
    type: "output",
    content:
      "\rSucessfully sent an Anonymous bird to hit the pig..." +
      "\rSucessfully sent a Lambda Expression bird to hit the pig...\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This example defines a functional interface and tries to access the method in it using both anonymous inner class syntax and usign Java 8 lambda expressions syntax." +
      "<div class='lineBreak' /> " +
      "Using the anonymous inner class syntax is understandable but we are creating a new class with the " +
      "complete method declaration and the logic inside the method. However, in case of lambda expressions, " +
      "we are doing the same using a single statement. So, why should we need to provide the entire class and " +
      "method definitions when our task is to add logic only to the method in the class. Lambda expresssions does exactly this." +
      "<div class='lineBreak' /> " +
      "In the above example, we refer to the method <code>hitThePig()</code> by just <code>()</code>. The logic inside the method is provided on the right " +
      "had side of <code>-></code> operator. This is like saying to the compiler &quot;call the only method in the functional interface whose " +
      "implementation is provided after <code>-></code> operator.&quot;" +
      "<div class='lineBreak' /> " +
      "If the method accepts a parameter, say the color of the bird then the syntax slightly varies." +
      "<div class='lineBreak' /> " +
      "The same is shown with an example below:"
  },
  {
    type: "gist",
    content: "a51add94028c973020c4ff95a6af5f67"
  },
  {
    type: "output",
    content:
      "\rSucessfully sent an Anonymous bird with color RED to hit the pig..." +
      "\rSucessfully sent a Lambda Expression bird with color RED to hit the pig...\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Observe now that the method <code>hitThePig(birdColor)</code> now accepts an argument called birdColor. The rest is self-explanatory."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "The data type of the argument birdColor in the below statement is a not mandatory requirement. " +
          "That means instead of writing the following statement," +
          "<div class='lineBreakSmall' /> " +
          "<code>hitThePigUsingAngryBird((String birdColor) -> ...</code>" +
          "<div class='lineBreakSmall' /> " +
          "we can simply omit the data type and modify the statement as below:" +
          "<div class='lineBreakSmall' /> " +
          "<code>hitThePigUsingAngryBird((birdColor) -></code>" +
          "This is perfectly legal and valid syntax." +
          "<div class='lineBreak' /> "
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/java8-functional-interfaces/",
    previousText: "Java 8 - Functional Interface",
    nextURL: "/java8-stream/",
    nextText: "Java 8 - Stream"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/java8-lambda-expressions.zip"
  }
];

const LambdaExpressions = () => {
  return (
    <>
      <Article header={header} data={data} />
    </>
  );
};

export default LambdaExpressions;
