import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["webClientTesting"],
  ...allHashTags,
  channel: "25"
};

const data = [
  {
    type: "toc",
    title: "WebClient Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/spring-webclient-get-post-put-delete-example' target='_blank'>" +
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> 4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer - [This article]</h4>"
  },
  {
    type: "subheading",
    name: "mockito-mockwebserver-webtestclient-wiremockserver",
    title: "Mockito, MockWebServer, WebTestClient and WireMockServer"
  },
  {
    type: "text",
    content:
      "In the previous article, we created a Rest API using WebClient. In this article, " +
      "we are going to Test or Mock the Rest API that we created using multiple different ways. " +
      "We will also try to generate code coverage reports for each of these approaches." +
      "<div class='lineBreak' /> " +
      "We will also compare and help you with which approach you can use based on your needs. So sit tight and follow along."
  },
  {
    type: "text",
    content:
      "<h4>Dependencies</h4>" +
      "Let's start by adding all the required dependencies for all the 4 approaches. Observe that " +
      "we have also added the jacoco plugin to verify if our test cases are doing the code coverage."
  },
  {
    type: "gist",
    content: "acde29ad05e4fa098e77bc6b09df9ee5"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>WebClient configuration</h4>" +
      "Create a configuration class and add a bean for the WebClient initialization."
  },
  {
    type: "gist",
    content: "516c50dcebd2bd5a57b70f4f099fe131"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 1: WebClient mocking using Mockito</h3>"
  },
  {
    type: "gist",
    content: "9cdb29991f973aeb11d0f3b220c4022d"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>Mockito Approach - Analysis</h4>" +
      "Type the below command at command prompt to run all the 4 CRUD test cases:" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean test -Dtest=SpringBootWebClientApplicationMockitoTests</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Navigate to <code>target > site > jacoco</code> and observe that the code coverage is 100%"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-mockito.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 2: WebClient mocking using MockWebServer</h3>"
  },
  {
    type: "gist",
    content: "46352d99d84363227a19b036b0274f21"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>MockWebServer Approach - Analysis</h4>" +
      "Type the below command at command prompt to run all the 4 CRUD test cases:" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean test -Dtest=SpringBootWebClientApplicationMockWebServerTests</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Navigate to <code>target > site > jacoco</code> and observe that the code coverage is again 100% similar to " +
      "Mockito approach."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-mockito.jpg"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 3: WebClient testing using WebTestClient</h3>"
  },
  {
    type: "gist",
    content: "a9c4750e3674c6f95cc001efd3cf776b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>WebTestClient Approach - Analysis</h4>" +
      "Type the below command at command prompt to run all the 4 CRUD test cases:" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean test -Dtest=SpringBootWebClientApplicationWebTestClientTests</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Yes, this will fail with errors because WebTestClient needs the API to be up and running. " +
      "Start the application using the below command and run the test cases again:" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn spring-boot:run</code>" +
      "<div class='lineBreakSmall' /> " +
      "Now, navigate to <code>target > site > jacoco</code> and observe the code coverage. Boom!! " +
      "the code coverage is 0% for all our CRUD methods."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-webtestclient.jpg"
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Way 4: WebClient testing using WireMockServer</h3>"
  },
  {
    type: "gist",
    content: "0c61310f58f0363ce58a94765ae8fdac"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>WireMockServer Approach - Analysis</h4>" +
      "Type the below command at command prompt to run all the 4 CRUD test cases:" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean test -Dtest=SpringBootWebClientApplicationWireMockTests</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Now, navigate to <code>target > site > jacoco</code> and observe the code coverage. Again, " +
      "the code coverage is 0% for all our CRUD methods."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-webtestclient.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Comparision - Which one should i choose ?</h3>" +
      "As you can see the Mockito and MockWebServer approaches are the right choices if you want unit testing and code coverage. " +
      "If you want to do integration testing then go for WebTestClient or WireMockServer. " +
      "<div class='lineBreakSmall' /> " +
      "Also, note that if you choose WebTestClient " +
      "approach, your API needs to be running. All other approaches can run without your API up and running. Hope this helps in deciding " +
      "which approach to go with."
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/spring-webclient-get-post-put-delete-example/",
    previousText:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    nextURL: "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    nextText:
      "3 ways to convert SOAPMessage to Java Object with namespace and XML to SOAPMessage"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/SpringBootWebClient.zip"
  }
];

class webClientTesting extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default webClientTesting;
