import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["pkcsAndPemToJks"],
  ...allHashTags,
  channel: "29"
};

const data = [
  {
    type: "toc",
    title: "SSL Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/easy-guide-to-ssl' target='_blank'>" +
      "Easy Guide to SSL - All the terms you need to know</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> Convert PKCS#12 to JKS and PEM to JKS format - [This article]</h4>" +
      "<h4><b>Part 3 : </b> <MyLink class='homelink' href='/tls-mutual-auth-two-way-ssl-postman-soapui' target='_blank'>" +
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "introduction",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "There are lots of articles on converting from PKCS#12 to PEM or extracting artifacts from PEM but " +
      "most of them do not work properly or missing some information. We are going to present you with working " +
      "commands along with all the missing or undocumented information." +
      "<div class='lineBreak' /> " +
      "If you are new to these formats or want to brush up your knowledge on the terms, refer to this " +
      "<MyLink class='homelink' href='/easy-guide-to-ssl' target='_blank'>" +
      "Easy Guide to SSL - All the terms you need to know</MyLink>"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "As said earlier, we will convert using real files and not just give out some commands. In this article, we will be using " +
      "sample files from <MyLink class='homelink' href='https://badssl.com' target='_blank'>" +
      "www.badssl.com</MyLink>. Before we proceed further, we want to appreciate this website for all the very useful " +
      "information they put together. You can actually do all kinds of certificate related testing against this website." +
      "<div class='lineBreak' /> " +
      "Alright, let's get our hands dirty a bit. First, go ahead and download the p12 and pem files from " +
      "<MyLink class='homelink' href='https://badssl.com/download/' target='_blank'>here</MyLink>."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "It's very important to know that on windows, the <code style='white-space: normal'>openssl</code> tool is not available by default so we will use <b>Git Bash</b> command line. " +
          "<b>Git Bash</b> comes with <code style='white-space: normal'>openssl</code> which makes our life easlier."
      }
    ]
  },
  {
    type: "subheading",
    name: "convert-pkcs#12-to-jks",
    title: "Convert PKCS#12(.p12) to JKS"
  },
  {
    type: "text",
    content: "<h3>1. Retrieve Alias name from the store</h3>"
  },
  {
    type: "text",
    content:
      "This is required. If you provide a wrong alias name, you will be greeted " +
      "with an exception saying alias does not exist."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>keytool -v -list -storetype pkcs12 -keystore badssl.com-client.p12 | grep Alias</code>"
  },
  {
    type: "output",
    content:
      "\r$ keytool -v -list -storetype pkcs12 -keystore badssl.com-client.p12 | grep Alias\r" +
      "\rEnter keystore password:  badssl.com" +
      "\rAlias name: 1\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>2. Convert to JKS format</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>keytool -importkeystore -srckeystore badssl.com-client.p12 -destkeystore badssl.com-client-jks.jks -srcstoretype pkcs12 -alias 1</code>" +
      "<div class='lineBreak' /> " +
      "This will create a file named<code style='white-space: normal'>badssl.com-client-p12.jks</code>. However, this is in java proprietary format. It is recommended " +
      "to always convert into PKCS#12 format. To do so, issue the below command," +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>keytool -importkeystore -srckeystore badssl.com-client-jks.jks -destkeystore badssl.com-client-p12.jks -deststoretype pkcs12</code>"
  },
  {
    type: "output",
    content:
      "\r$ keytool -importkeystore -srckeystore badssl.com-client-jks.jks -destkeystore badssl.com-client-p12.jks -deststoretype pkcs12\r" +
      "\rImporting keystore badssl.com-client-jks.jks to badssl.com-client-p12.jks..." +
      "\rEnter destination keystore password:  badssl.com" +
      "\rRe-enter new password: badssl.com" +
      "\rEnter source keystore password:  badssl.com" +
      "\rEntry for alias 1 successfully imported." +
      "\rImport command completed:  1 entries successfully imported, 0 entries failed or cancelled\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "convert-pem-to-jks",
    title: "Convert PEM(.pem) to JKS"
  },
  {
    type: "text",
    content: "<h3>1. Extract Private Key from PEM file</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>winpty openssl pkey -in badssl.com-client.pem -out private-key.pem</code>"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "Observe the <code style='white-space: normal'>winpty</code> command at the beginning. " +
          "This is very important on windows and without this command, the openssl will hang waiting indefinitely."
      }
    ]
  },
  {
    type: "output",
    content:
      "\r$ winpty openssl pkey -in badssl.com-client.pem -out private-key.pem\r" +
      "\rEnter pass phrase for badssl.com-client.pem:\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> <h3>2. Extract Certificate from PEM file</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>winpty openssl x509 -in badssl.com-client.pem -out certificate.crt</code>"
  },
  {
    type: "output",
    content:
      "\r$ winpty openssl x509 -in badssl.com-client.pem -out certificate.crt\r" +
      "\r$\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "If you included -outform -der in the above command like, " +
          "<div class='smallLineBreak' /> " +
          "<code style='white-space: normal'>winpty openssl x509 <b>-outform der</b> -in badssl.com-client.pem -out certificate.crt</code> " +
          "<div class='smallLineBreak' /> " +
          "then you will see an error message(<b>unable to load certificates</b>) while using/converting this certificate. To fix this error, run this additional command," +
          "<div class='smallLineBreak' /> " +
          "<code style='white-space: normal'>openssl x509 -inform DER -in certificate.crt -out certificate-1.crt</code> "
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> <h3>3. Convert Private Key and Certificate into a single PKCS#12 file</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now that we have the Certificate and Private Key, let's combine these two into a single file and provide a password." +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>winpty openssl pkcs12 -export -in certificate.crt -inkey private-key.pem -name shared -out cert-and-key.p12</code>"
  },
  {
    type: "output",
    content:
      "\r$ winpty openssl pkcs12 -export -in certificate.crt -inkey private-key.pem -name shared -out cert-and-key.p12\r" +
      "\rEnter Export Password:" +
      "\rVerifying - Enter Export Password:" +
      "\r$\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "If you have a chain of certificates, use the below command instead, " +
          "<div class='smallLineBreak' /> " +
          "<code style='white-space: normal'>winpty openssl pkcs12 -export -inkey private_key.pem -in certificate.crt -certfile chain_of_certificates.pem -name <name> -out certs-and-key.p12</code> "
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> <h3>4. Import PKCS#12 file into JKS</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'> keytool -importkeystore -srckeystore cert-and-key.p12 -destkeystore badssl.com-client-pem.jks -srcstoretype pkcs12 -deststoretype pkcs12 -alias shared</code>"
  },
  {
    type: "output",
    content:
      "\r$  keytool -importkeystore -srckeystore cert-and-key.p12 -destkeystore badssl.com-client-pem.jks -srcstoretype pkcs12 -deststoretype pkcs12 -alias shared\r" +
      "\rImporting keystore cert-and-key.p12 to badssl.com-client-pem.jks..." +
      "\rEnter destination keystore password:  badssl.com" +
      "\rRe-enter new password: badssl.com" +
      "\rEnter source keystore password:  badssl.com" +
      "\r$\r\n"
  },
  {
    type: "subheading",
    name: "conclusion",
    title: "Conclusion"
  },
  {
    type: "text",
    content:
      "Now that we have created JKS stores from PKCS#12 and PEM files, we will use these " +
      "files in our next article to test and see how to configure TLS Mutual Authentication, also called as Two way SSL."
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/easy-guide-to-ssl/",
    previousText: "Easy Guide to SSL - All the terms you need to know",
    nextURL: "/tls-mutual-auth-two-way-ssl-postman-soapui/",
    nextText:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/pkcs-pem-jks.zip"
  }
];

class PKCSAndPEMToJKS extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default PKCSAndPEMToJKS;
