import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class RecentArticle extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, imgLink, altText, link } = this.props;
    return (
      <div className="col-md-3">
        <div className="box">
          <div className="overlay-container">
            <div>
              <figure style={{ textAlign: "center" }}>
                <Link title={title} to={link}>
                  <img
                    className="overlay-item"
                    style={{ width: "280px" }}
                    height="200px"
                    width="280px"
                    src={imgLink}
                    alt={altText}
                  />
                </Link>
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecentArticle.propTypes = {
  title: PropTypes.string,
  imgLink: PropTypes.string,
  altText: PropTypes.string,
  link: PropTypes.string
};

export default RecentArticle;
