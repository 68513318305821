import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["restfulClientUsingCXF"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "8"
};

const data = [
  {
    type: "subheading",
    name: "restful-client-using-cxf",
    title: "Client"
  },
  {
    type: "text",
    content:
      "In our <MyLink class='homelink' href='/restful-webservice-using-cxf' target='_blank'>JAX-RS webservice using CXF</MyLink> " +
      "tutorial, we have created a restful webservice that contains two methods. One method accepts text type input while the second one accepts a JsonBean type. " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Remember, in our test case, HelloAngryWorldTest.java, by default, when the file was created using maven, there were two methods that tests both text and JsonBean return types. It is modified it a bit to have an extra method as below: " +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>1. HelloAngryWorldTest.java :</h4>"
  },
  {
    type: "gist",
    content: "18b7fafb5956c4e1a6def2b47301f060"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "In Apache CXF, there is a helper class called <code>org.apache.cxf.jaxrs.client.WebClient</code>. This helper class is used to create the client. This class reduces the code to create a response to just two lines (see <code>testPing()</code> method). Without this helper class, we would need to create a lot of objects." +
      "<div class='lineBreakSmall' /> " +
      "An example restful client with(see, <code>testPing()</code>) and without(see, <code>testPingUsingPureJaxRS()</code>) using the helper class are shown in the above example. The third method, <code>testJsonRoundtrip()</code> demonstrates usage of jsonBean return type." +
      "<div class='lineBreakSmall' /> " +
      "<code>testPingUsingPureJaxRS()</code> method uses pure JAX-RS classes for creating the response. Using pure JAX-RS classes the response creation will be as follows :" +
      "<div class='lineBreakSmall' /> " +
      "<b>Client -> WebTarget -> Invocation.Builder -> Response</b>" +
      "<div class='lineBreakSmall' /> " +
      "Using Apache CXF helper class the reponse creation becomes as simple as below :" +
      "<div class='lineBreakSmall' /> " +
      "<b>WebClient -> Response</b>"
  },
  {
    type: "subheading",
    name: "testing-the-webservice",
    title: "Testing the webservice:"
  },
  {
    type: "text",
    content:
      "There are number of ways you can test the Restful webservice. Before running any of the below, make sure the tomcat server is started and the webservice is running using," +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean package -DskipTests=true tomcat7:run</code> or <code>mvn clean package -DskipTests=true jetty:run</code>"
  },
  {
    type: "text",
    content:
      "<h4>1. Using maven command :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "i. Specify the service.url at command line :" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn -Dservice.url=http://localhost:&lt;port-number&gt;/jaxrs-service test</code>" +
      "<div class='lineBreakSmall' /> " +
      "This command uses a command line argument to specify the service.url. The service.url is used in the test case." +
      "<div class='lineBreakSmall' /> " +
      "ii. Specify the service.url in pom.xml"
  },
  {
    type: "gist",
    content: "0952c16398f22dba667d0efe6b909259"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Add the above configuration and use the below command to test the webservice," +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn test</code>" +
      "<div class='lineBreakSmall' /> " +
      "iii. Hard-code the service.url in HelloWorldTest.java and use the below command to run the tests." +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn test</code>"
  },
  {
    type: "output",
    content:
      "\r-------------------------------------------------------" +
      "\rT E S T S" +
      "\r-------------------------------------------------------" +
      "\rRunning com.learninjava.HelloAngryWorldTest" +
      "\rResponse from web service is : Birds" +
      "\rResponse from web service is : Tweet" +
      "\rResponse from web service is : Tweet" +
      "\rTests run: 3, Failures: 0, Errors: 0, Skipped: 0, Time elapsed: 41.107 sec\r" +
      "\rResults :\r" +
      "\rTests run: 3, Failures: 0, Errors: 0, Skipped: 0\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "<h4>2. Using Poster plugin :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "Poster is a plugin for firefox browser. This is a very useful and handy tool to test your restful webservices quickly." +
      "<div class='lineBreak' /> " +
      "i. To test the ping method of HelloAngryWorld, enter the following in Poster UI :" +
      "<div class='lineBreak' /> " +
      "<b>URL</b>: http://localhost:&lt;port-number&gt;/jaxrs-service/helloAngryWorld/echo/tweet" +
      "<div class='lineBreakSmall' /> " +
      "<b>Content Type</b>:   application/text" +
      "<div class='lineBreakSmall' /> " +
      "and click on GET button as ping is a GET request type. The response is displayed in the Response window."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-text.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "ii. To test the <code>modifyJson</code> method of HelloAngryWorld, enter the following in Poster UI :" +
      "<div class='lineBreakSmall' /> " +
      "<b>URL</b>: http://localhost:&lt;port-number&gt;/jaxrs-service/helloAngryWorld/jsonBean" +
      "<div class='lineBreakSmall' /> " +
      "<b>Content Type</b>:   application/json" +
      "<div class='lineBreakSmall' /> " +
      "<b>Payload(Text Box at bottom)</b>:  { &quot;val1&quot;: &quot;Birds&quot;, &quot;val2&quot;: &quot;Eggs&quot; }" +
      "<div class='lineBreakSmall' /> " +
      "and click on POST button as modifyJson is a POST request type. The response is displayed in the Response window."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-json.png"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/jaxws-soap-webservice-using-cxf/",
    previousText: "JAX-WS SOAP WebService using CXF",
    nextURL: "/restful-webservice-using-cxf/",
    nextText: "JAX-RS Restful WebService using CXF"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/cxfjaxrsws.zip"
  }
];

class RestfulClientUsingCXF extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default RestfulClientUsingCXF;
