import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import "App.css";
import IndexRoutes from "components/common/IndexRoutes.jsx";
import ScrollToTop from "ScrollToTop";

const App = () => (
  <BrowserRouter>
    <Switch>
      {IndexRoutes.map((prop, key) => {
        return (
          <ScrollToTop key={key}>
            <Route path={prop.path} component={prop.component} key={key} />
          </ScrollToTop>
        );
      })}
    </Switch>
  </BrowserRouter>
);

export default App;
