import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactRouterApacheNginxTomcat"],
  ...allHashTags,
  channel: "13"
};

const data = [
  {
    type: "subheading",
    name: "react-router-config",
    title: "Concept"
  },
  {
    type: "text",
    content:
      "So, you are here after browsing through half of the internet searching " +
      "about configuring react router or SPA in general for your production " +
      "and could not get the actual idea on why its not working or what&#39;s the concept ?" +
      "<div class='lineBreak' /> " +
      "Well! You are in the right place, sit tight, this is not just another " +
      "react router configuration tutorial. We will explain what you dont find over the internet: " +
      "The actual concepts of routing along with production ready configuration on three most famous servers: " +
      "<b>Apache HTTP Server, Nginx and Tomcat</b>."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "client-side-vs-server-side",
    title: "Client Side Vs Server Side Routing"
  },
  {
    type: "text",
    content:
      "Why two routings required ? Why is this confusion ? Why cant we have only one ? " +
      "Since SPA is client side application, why not only Client side routing works ? " +
      "So many questions asked. Enough!!, Here are the answers," +
      "<div class='lineBreak' /> " +
      "See the below image, it shows how the routing works in Server side and Client side. " +
      "As you can see, in a multi page application, a java web application for instance, the server refers to a configuration file. " +
      "This configuration file could be a simple web.xml or if you are using a framework like Spring, it could be a applicationContext.xml file. " +
      "No matter what framework you use or what configuration file you use, all it has is the routing information."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "When a user clicks on a link to navigate to another page, the routing information is looked up in the configuration file. " +
      "Then server returns the target page and the user sees the new html page. Since server is taking care of the routing its called " +
      "Server side routing." +
      "<div class='lineBreak' /> " +
      "Now compare this to the single page application. In this case, there is only a single page, <code>index.html</code>. " +
      "The webserver always returns <code>index.html</code> for any routing. This is because all the code is in a single page. This is why all the " +
      "SPA frameworks has their own routing frameworks. In react, its the react-router framework. "
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Such a client side routing works fine in development environment. But how can you make sure it works on a production server ? " +
      "Since the production servers are created before SPA frameworks came in to usage, we need special instructions for them to work with newer frameworks. " +
      "That means we need a combination of Server Side and Client Side Routings." +
      "<div class='lineBreak' /> " +
      "This is why when you directly push your code to production, you will see <code>404 Not Found</code> error when you refresh. " +
      "This is because when you refresh on a page with URL &quot;/user&quot;, the server tries to find that route which will not be there in server configuration " +
      "The basic gist of all this conversation is this very concept of combination of Server Side Routing and Client Side Routing. " +
      "When you try to route to a page say &quot;/user&quot;, the server tries to find that page. We need to fool the server by always directing it to <code>index.html</code>. " +
      "Once we are inside <code>index.html</code>, our client side routing in SPA will take care of the rest."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "See below for the production ready configurations that does exactly what is explained above. "
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<li>You will need both Server Side and Client Side Routing for an SPA</li>" +
          "<li>You will see <code>404 Not Found</code> errors if Server Side Routing is missing or misconfigured</li>" +
          "<li>Server should be configured to always serve <code>index.html</code> irrespective of route</li>"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "We have configured react router on Create React App (CRA) scaffolding application. Let us see how to make it run on all three servers. " +
      "Dont worry we have included the working application at the end. Check &quot;Download Source&quot; section. " +
      "<div class='lineBreakSmall' /> " +
      "We are configuring server side configuration here, If you want to setup client side routing painlessly, head over to this easy to understand step by step tutorial - " +
      "<MyLink class='homelink' href='/create-react-app-react-router' target='_blank'><span>Create React App - React Router Setup</span></MyLink>"
  },
  {
    type: "subheading",
    name: "hashrouter-vs-browserrouter",
    title: "HashRouter Vs BrowserRouter"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "There is enough information on what are the differences between HashRouter and BrowserRouter so we are not going to see the differences. " +
      "However, we want you to understand which is used when and how they work. Think of HashRouter as a development environment routing. Although " +
      "it can work on production, it appends a <code>#</code> in the URL which would not feel like production URLs. " +
      "<div class='lineBreak' /> " +
      "HashRouter uses history library and anchor blocks, also called as html bookmarks. " +
      "This is the reason you dont need any server side configuration to make it working. But hold on, are you ok to have your URLs appended with an ugly <code>#</code>. Imagine you " +
      "also have html bookmarks or anchor blocks in your pages. Then the URLs looks more uglier." +
      "<div class='lineBreak' /> " +
      "This is where the BrowserRouter comes in. Although it requires server side routing " +
      "to be setup for them to work properly. This is why we used BrowserRouter, to make your routing production ready." +
      "<div class='lineBreak' /> " +
      "For your reference this is how our URLs would look like if HashRouter is used: " +
      "<div class='lineBreakSmall' /> " +
      "<code style='white-space: normal'>http://localhost:8080/react-routing-config/#/dashboard</code>" +
      "<div class='lineBreakSmall' /> " +
      "<code style='white-space: normal'>http://localhost:8080/react-routing-config/#/user</code>" +
      "<div class='lineBreakSmall' /> " +
      "Not so pretty right !! Read on.."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<li>HashRouter requires no Server Side Routing but appends <code>#</code> characters to the URLs which does not look pretty on a production environment.</li>" +
          "<li>BrowserRouter requires Server Side Routing which is where everyone struggles to setup. But not any more, after you read through this tutorial</li>"
      }
    ]
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "apache-http-server",
    title: "Apache HTTP Server - 2.4.43"
  },
  {
    type: "text",
    content: "<h4>1. httpd.conf - default context location (/htdocs) :</h4>"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<li>First we need to enable <code>LoadModule rewrite_module modules/mod_rewrite.so</code> module for the configurations to work</li>"
      }
    ]
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "7f5d58ef507ab6499a61713c0b8bff1a"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. httpd.conf - custom context location (say, /react-routing-config) :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This one is a bit tricky one, we need to tell your build tool to build with the context name and use that build instead. " +
      "Also, we need to create a Virtual Host and add configuration using &lt;Directory&gt; tag. Here are the steps: " +
      "<div class='lineBreak' /> " +
      "<h5>a. Add <code>basename</code> as your context name in BrowserRouter tag</h5>"
  },
  {
    type: "gist",
    content: "94859a2d3873d885cd3bcabf2adfd4a6"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>b. Add <code>homepage</code> in package.json</h5>"
  },
  {
    type: "gist",
    content: "609f87db539c21d0cd51a30b2927b32e"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h5>c. Finally, the <code>httpd.conf</code> configuration</h5>"
  },
  {
    type: "gist",
    content: "89e6998fe59001897f00ae1832f3785a"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>3. .htaccess file</h4>"
  },
  {
    type: "gist",
    content: "20e81d7aa0a8adc73068183c367aa7f6"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The -f, -d and -l flags represents file, directory and a symbolic link respectively. The configuration says that " +
      "when you receive a request from index.html do nothing as its already in index.html, next follows the non index.html requests like &quot;/user&quot;. " +
      "In this case, we check if the request is not a file, directory or symbolic link, if so just direct them to index.html." +
      "<div class='lineBreakSmall' /> " +
      "Now, navigate to the dashboard screen and user screen and make sure you are not getting 404 errors when you refresh."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router-dashboard.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router-user.jpg"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "subheading",
    name: "nginx",
    title: "Nginx - 1.16.1"
  },
  {
    type: "text",
    content:
      "<h4>1. nginx.conf - default context location (/html) :</h4>" +
      "<div class='lineBreak' /> " +
      "This configuration is pretty simple. All we have to do is add <code>try_files</code>"
  },
  {
    type: "gist",
    content: "b7e2c563d15fb536ba5d0c59d4a8a790"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. nginx.conf - custom context location (say, /react-routing-config) :</h4>" +
      "<div class='lineBreak' /> " +
      "Build the application with the context name and add the required configuration on server. Here are the steps: " +
      "<div class='lineBreak' /> " +
      "<h5>a. Add <code>basename</code> as your context name in BrowserRouter tag</h5>"
  },
  {
    type: "gist",
    content: "94859a2d3873d885cd3bcabf2adfd4a6"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>b. Add <code>homepage</code> in package.json</h5>"
  },
  {
    type: "gist",
    content: "609f87db539c21d0cd51a30b2927b32e"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h5>c. Finally, the <code>nginx.conf</code> configuration</h5>"
  },
  {
    type: "gist",
    content: "7af764ab40631a4c64c4d9c564e6521d"
  },
  {
    type: "subheading",
    name: "tomcat",
    title: "Tomcat - 10.0.0"
  },
  {
    type: "text",
    content:
      "Tomcat configuration is a bit different than other two servers. Since Tomcat only serves a web application, " +
      "we need to modify our application structure to look like the structure of a Java Web Application. "
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>Using <code>&lt;error-page&gt;</code> in web.xml :</h4>" +
      "<div class='lineBreak' />" +
      "Create <code>WEB-INF</code> folder and create a <code>web.xml</code> file as below and proceed with the configurations. " +
      "The idea is to redirect always to index.html when 404 error occurs." +
      "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "5d81600c60f1521269871f827616d047"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>Using <code>rewrite.config</code> and <code>Valve</code> :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "If for some reason you dont want to use error-page, there is another approach using <code>rewrite.config</code> file. " +
      "It uses a tomcat feature called <code>Valve</code>. " +
      "First remove the error-page tag from web.xml and Simply create a file as below and name it as <code>rewrite.config</code>. Place this file in <code>WEB-INF</code> folder." +
      "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "4417bde4ff67b47968829a5cfa750667"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "In the tomcat <code>server.xml</code>, add the below config and proceed with the configurations." +
      "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "e2ca04605b687e0190749e6c694bb5a0"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "<h4>1. Default context location (/ROOT) :</h4>" +
      "<div class='lineBreak' /> " +
      "This configuration is pretty simple. All we have to do is copy the application to the <code>ROOT</code> folder."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Custom context location (say, /react-routing-config) :</h4>" +
      "<div class='lineBreak' /> " +
      "Build the application with the context name and deploy the application to Tomcat. Here are the steps: " +
      "<div class='lineBreak' /> " +
      "<h5>a. Add <code>basename</code> as your context name in BrowserRouter tag</h5>"
  },
  {
    type: "gist",
    content: "94859a2d3873d885cd3bcabf2adfd4a6"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>b. Add <code>homepage</code> in package.json</h5>"
  },
  {
    type: "gist",
    content: "609f87db539c21d0cd51a30b2927b32e"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h5>c. Finally, deploy the application to react-routing-config directory in webapps</h5>"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/create-react-app-react-router/",
    previousText:
      "React Router Configuration - Create React App - Step by Step Configuration",
    nextURL: "/react-proxying-api-backend-apache-nginx-tomcat/",
    nextText:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-routing-config.zip"
  }
];

class ReactRoutingConfig extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactRoutingConfig;
