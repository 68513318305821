import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["createReactAppReactRouter"],
  ...allHashTags,
  channel: "14"
};

const data = [
  {
    type: "subheading",
    name: "create-react-app-tutorial",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Configuring react router is probably the most painful things in react. " +
      "There are thousands of tutorials and posts but none explains the production ready configuration. " +
      "We will try to make this process easy. So follow along with this easy to understand " +
      "Step by Step tutorial." +
      "<div class='lineBreak' />"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Without wasting much time, We will create two routes, /dashboard and /user. " +
      "Once we have configured the setup correctly, we will test these routes to validate." +
      "<div class='lineBreak' /> " +
      "First, create a Create React App (CRA) scaffolding application using the command: " +
      "<div class='lineBreakSmall' /> " +
      "<code style='white-space: normal'>npx create-react-app react-routing-config</code>" +
      "<div class='lineBreak' /> " +
      "This command will create a bunch of files to get you started. We will modify some files and add few others. Before that, you need to install react router for everything to work fine. " +
      "Install react router using the command: " +
      "<div class='lineBreakSmall' /> " +
      "<code style='white-space: normal'>npm install react-router-dom</code>"
  },
  {
    type: "subheading",
    name: "react-router-and-react-router-dom-which-one-to-use",
    title: "react-router Vs react-router-dom confusion"
  },
  {
    type: "text",
    content:
      "There is a confusion among developers as to which package to import, <code style='white-space: normal'>react-router</code> or <code style='white-space: normal'>react-router-dom</code> ? " +
      "Basically, <code style='white-space: normal'>react-router</code> package has the core routing functionality. However, you dont have to use it directly. " +
      "<div class='lineBreak' /> " +
      "You should be using either <code style='white-space: normal'>react-router-dom</code> for a web application or <code style='white-space: normal'>react-router-native</code> for a React Native application. " +
      "In either case, <code style='white-space: normal'>react-router</code> package will be installed automatically as a dependency. " +
      "<div class='lineBreak' /> " +
      "Thats all for the concepts!! Follow along, here is the step by step tutorial to configure routing: " +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content:
      "<h4>1. Create Routes.js and create two pages for testing routing setup :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h5>a. Create Routes.js :</h4>"
  },
  {
    type: "gist",
    content: "2c46af18e069129473caddfc6acec9f9"
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h5>b. Create Dashboard.js :</h4>"
  },
  {
    type: "gist",
    content: "057aa8c67cae98bd3030099839d364e0"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h5>c. Create User.js :</h4>"
  },
  {
    type: "gist",
    content: "9e49b5a653c8b13cefa53921ce54f544"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Modify existing index.js and configure BrowserRouter :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "dba29fb73601b798de5bd1c7777fa7ab"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>3. Modify App.js and render all the routes :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "073e00d0653ee5a668fc5ebd25ddea21"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>4. Modify package.json to include <code>homepage</code> property as below :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "70797db1b34ed8242f507820e8d05d35"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now start your application using <code>npm start</code> command." +
      "<div class='lineBreakSmall' /> " +
      "Navigate to <code>http://localhost:3000/react-routing-config/</code>, if eveything is setup properly, you " +
      "should see a page similar to the below screen. The Dashboard screen will appear by default as it is configured as " +
      "our default route."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router-dashboard.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "When you click on the <code>User</code> link, you should navigate to the User screen. " +
      "Again on User screen, when you click on <code>Dashboard</code> link you should be taken back to Dashboard. " +
      "This confirms that our client side routing is working fine as expected." +
      "<div class='lineBreakSmall' /> " +
      "For a full working application, refer to the &quot;Download Source&quot; section at the end of this tutorial."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router-user.jpg"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Alright everything is working fine. But wait !! This is working fine only in your " +
      "development environment as we have setup only client side routing configuration. " +
      "<div class='lineBreak' /> " +
      "To make sure the application runs fine on production too, you need to do some configuration " +
      "on your server side as well. Refer to this tutorial for the complete configuration for your favorite server : " +
      "<MyLink class='homelink' href='/react-router-apache-nginx-tomcat' target='_blank'><span>React Router Configuration - Apache Http Server, Nginx, Tomcat</span></MyLink>"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-proxying-api-backend-apache-nginx-tomcat/",
    previousText:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/react-router-apache-nginx-tomcat/",
    nextText: "React Router Configuration - Apache Http Server, Nginx, Tomcat"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-routing-config.zip"
  }
];

class CRAReactRoutingConfig extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default CRAReactRoutingConfig;
