import {
  anyCategories,
  allCategories,
  allURLs
} from "components/Properties/AllURLs.jsx";
import MiniSearch from "minisearch";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab);
library.add(fas);

import {
  IconLookup,
  IconDefinition,
  findIconDefinition
} from "@fortawesome/fontawesome-svg-core";

export function getSearchResultsById(id) {
  return allCategories[id] != null ? allCategories[id] : "";
}

export function getSearchResultsByAny() {
  return anyCategories["any"] != null ? anyCategories["any"] : "";
}

export function getSearchResultsByCriteria(params) {
  let miniSearch = new MiniSearch({
    fields: ["desc", "title", "category", "meta.content"], // fields to index for full-text search
    storeFields: [
      "title",
      "desc",
      "icon",
      "iconType",
      "iconColor",
      "authorName",
      "authoredDate",
      "url",
      "meta"
    ] // fields to return with search results
  });
  miniSearch.addAll(allURLs);
  return miniSearch.search(params.criteria, { prefix: true });
  //}
}

export function getSearchResultsOrderedById() {
  let data = getSearchResultsByAny();
  return data.sort(function(a, b) {
    return b.id - a.id; // latest first
  });
}

export function findAndRemove(array, property, value) {
  array.forEach(function(result, index) {
    if (result[property] === value) {
      array.splice(index, 1);
    }
  });
  return array;
}

export function getFAIconByName(name) {
  const iconLookup: IconLookup = {
    prefix: name.split("-")[0],
    iconName: name.split("-")[1]
  };
  const iconDefinition: IconDefinition = findIconDefinition(iconLookup);
  return iconDefinition;
}

export function getObjectFromJSONByValue(data, key, value) {
  return data.filter(function(data) {
    return data[key] == value;
  });
}
