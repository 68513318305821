const allHashTags = {
  hashTags: [
    "java",
    "springboot",
    "react",
    "angular",
    "webservices",
    "spring",
    "javascript"
  ],
  hashTagColors: [
    "blue",
    "warning",
    "info",
    "danger",
    "success",
    "green",
    "brown"
  ]
};

export default allHashTags;
