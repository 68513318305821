import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { hashtag } from "react-icons-kit/fa";
import { Helmet } from "react-helmet";
import {
  cloudDownload,
  arrowCircleLeft,
  arrowCircleRight
} from "react-icons-kit/fa";

import ReactWidgetPackComments from "react-widgetpack-comments";

// eslint-disable-next-line no-unused-vars
import CustomGist from "components/CustomWidgets/CustomGist";
import OutputBox from "components/CustomWidgets/OutputBox";
import InfoBox from "components/CustomWidgets/InfoBox";
import RenderIcon from "components/CustomWidgets/RenderIcon";
import { getFAIconByName } from "components/common/CommonFunctions.jsx";
import GoogleAdContainer from "components/Ads/GoogleAdContainer";
import EmailSubscriptionContainer from "components/Ads/EmailSubscriptionContainer";
import Left from "components/Layouts/Left.jsx";
import Right from "components/Layouts/Right.jsx";
import commonMetaTags from "components/Properties/CommonMetaTags.jsx";
import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";
import AnalyticsTagContainer from "components/common/AnalyticsTagContainer";
import {
  faShareAlt,
  faThumbsUp,
  faComment
} from "@fortawesome/free-solid-svg-icons";

class Article extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { header, data } = this.props;
    let allMetaTags = [...header.meta, ...commonMetaTags.meta];
    return (
      <>
        <Helmet>
          <title>{header.metaTitle}</title>
          {allMetaTags.map((tag, key) =>
            allMetaTags[key].type.indexOf("og:") != -1 ||
            allMetaTags[key].type.indexOf("fb:") != -1 ? (
              allMetaTags[key].type.indexOf("og:url") != -1 ? (
                <meta
                  property={allMetaTags[key].type}
                  content={allMetaTags[key].content}
                  key={key}
                />
              ) : (
                <meta
                  property={allMetaTags[key].type}
                  content={allMetaTags[key].content}
                  key={key}
                />
              )
            ) : allMetaTags[key].type.indexOf("canonical") != -1 ? (
              <link
                rel="canonical"
                href={allMetaTags[key].content + "/"}
                key={key}
              />
            ) : (
              <meta
                name={allMetaTags[key].type}
                content={allMetaTags[key].content}
                key={key}
              />
            )
          )}
        </Helmet>
        <AnalyticsTagContainer />
        <div className="header-bar"></div>
        <div className="container-fluid">
          <section id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12" style={{ marginTop: "0px" }}>
                  <img
                    src={
                      "https://github.com/learninjavagithub/assets/raw/master/" +
                      header.category +
                      ".jpg"
                    }
                    height="100%"
                    width="100%"
                    alt={header.title}
                  />
                </div>
              </div>
              <div className="row">
                <Left />
                <div className="col-md-8">
                  <div>
                    <h1 style={{ marginTop: "10px" }}>{header.title}</h1>
                  </div>
                  <div>
                    <table
                      className="table table-borderless table-paddingless"
                      style={{ maxWidth: "60%" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              margin: "0px",
                              width: "20%",
                              textAlign: "center"
                            }}
                          >
                            <RenderIcon
                              name={getFAIconByName(header.authorImage)}
                              type="faIcon"
                              color={header.iconColor}
                              size={44}
                            />
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <h5 className="authorLine1">{header.authorName}</h5>
                            <h6 className="authorLine2">
                              {header.authoredDate}
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="hashTags">
                    {header.hashTags.map((tag, key) => (
                      <React.Fragment key={key}>
                        <Link
                          to={
                            tag === "java"
                              ? "/java-programming/"
                              : "/" + tag + "/"
                          }
                          className={"badge btn-" + header.hashTagColors[key]}
                        >
                          #{tag}
                        </Link>
                      </React.Fragment>
                    ))}
                  </div>
                  <article>
                    {data.map((contentItem, j) => (
                      <React.Fragment key={j}>
                        {contentItem.type == "subheading" && (
                          <React.Fragment>
                            <div id={contentItem.name}>&nbsp;</div>
                            <div
                              className="post-slider"
                              style={{ marginTop: "50px" }}
                            >
                              <div className="post-heading">
                                <h2 className="page-title">
                                  {contentItem.title}&nbsp;
                                  <a
                                    href={
                                      this.props.history.location.pathname +
                                      "#" +
                                      contentItem.name
                                    }
                                    className="anchorLink"
                                  >
                                    <RenderIcon name={hashtag} type="icon" />
                                  </a>
                                </h2>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {contentItem.type == "text" && (
                          <div
                            className="displayText"
                            dangerouslySetInnerHTML={{
                              __html: contentItem.content
                                .replace(/<MyLink/g, "<a")
                                .replace(/<\/MyLink/g, "</a")
                            }}
                          ></div>
                        )}
                        {contentItem.type == "gist" && (
                          <CustomGist gist={contentItem.content} />
                        )}
                        {contentItem.type == "output" && (
                          <OutputBox
                            className="outputBlock"
                            content={contentItem.content}
                          />
                        )}
                        {contentItem.type == "info" && (
                          <InfoBox content={contentItem.content} />
                        )}
                        {contentItem.type == "image" && (
                          <img
                            src={contentItem.content}
                            height={contentItem.height}
                            width={contentItem.width}
                            alt={contentItem.content}
                          />
                        )}
                        {contentItem.type == "toc" && (
                          <div className="toc-block">
                            <div style={{ textAlign: "center" }}>
                              <h3
                                className="underlined-text"
                                style={{ marginTop: "0px" }}
                              >
                                {contentItem.title}
                              </h3>
                            </div>
                            <div
                              className="displayText"
                              dangerouslySetInnerHTML={{
                                __html: contentItem.content
                                  .replace(/<MyLink/g, "<a")
                                  .replace(/<\/MyLink/g, "</a")
                              }}
                            ></div>
                          </div>
                        )}
                        {contentItem.type == "footnote" && (
                          <div className="displayText">
                            Thats all folks !! Happy coding. If you feel this
                            helped you, keep supporting us by &nbsp;
                            <span title="Share">
                              <RenderIcon
                                name={faShareAlt}
                                type="faIcon"
                                size={20}
                                color="#a40b0b"
                              />
                            </span>
                            &nbsp;or&nbsp;
                            <span title="Like">
                              <RenderIcon
                                name={faThumbsUp}
                                type="faIcon"
                                size={20}
                                color="#11766D"
                              />
                            </span>
                            &nbsp;or&nbsp;
                            <span title="Comment">
                              <RenderIcon
                                name={faComment}
                                type="faIcon"
                                size={20}
                                color="#e2a900"
                              />
                            </span>
                            &nbsp;below or on the articles on social media.
                          </div>
                        )}
                        {contentItem.type == "navLinks" && (
                          <>
                            <div className="lineBreak" />
                            <ul className="pager">
                              <li className="previous">
                                <a
                                  className="themedLink"
                                  target="blank"
                                  href={contentItem.previousURL}
                                  style={{ width: "300px" }}
                                >
                                  <h4 style={{ marginBottom: "10px" }}>
                                    <RenderIcon
                                      name={
                                        contentItem.icon
                                          ? contentItem.icon
                                          : arrowCircleLeft
                                      }
                                      type="icon"
                                      size={35}
                                      color={"#ef6c00"}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Previous Article
                                  </h4>
                                  <span className="themedDisplayText">
                                    {contentItem.previousText}
                                  </span>
                                </a>
                              </li>
                              <li className="next">
                                <a
                                  className="themedLink"
                                  target="blank"
                                  href={contentItem.nextURL}
                                  style={{ width: "300px" }}
                                >
                                  <h4 style={{ marginBottom: "10px" }}>
                                    Next Article&nbsp;&nbsp;&nbsp;&nbsp;
                                    <RenderIcon
                                      name={
                                        contentItem.icon
                                          ? contentItem.icon
                                          : arrowCircleRight
                                      }
                                      type="icon"
                                      size={35}
                                      color={"#ef6c00"}
                                    />
                                  </h4>
                                  <span className="themedDisplayText">
                                    {contentItem.nextText}
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <div className="lineBreak" />
                            <div className="lineBreak" />
                          </>
                        )}
                        {contentItem.type == "source" && (
                          <div
                            className="well well-sm"
                            style={{ textAlign: "center" }}
                          >
                            <a
                              className="themedLink"
                              target="blank"
                              href={contentItem.url}
                            >
                              <h4 style={{ marginBottom: "10px" }}>
                                <RenderIcon
                                  name={
                                    contentItem.icon
                                      ? contentItem.icon
                                      : cloudDownload
                                  }
                                  type="icon"
                                  size={30}
                                  color={"#3b5998"}
                                />
                                &nbsp;&nbsp;Download Source
                              </h4>
                            </a>
                          </div>
                        )}
                        {contentItem.type == "googleAd" && (
                          <div>
                            <div className="lineBreak" />
                            <GoogleAdContainer
                              position={contentItem.position}
                            />
                            <div className="lineBreak" />
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </article>
                  <div className="hashTags" style={{ textAlign: "center" }}>
                    {header.hashTags.map((tag, key) => (
                      <React.Fragment key={key}>
                        <Link
                          to={
                            tag === "java"
                              ? "/java-programming/"
                              : "/" + tag + "/"
                          }
                          className={"badge btn-" + header.hashTagColors[key]}
                        >
                          #{tag}
                        </Link>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="row">&nbsp;</div>
                  <div>
                    <EmailSubscriptionContainer enable={true} />
                  </div>

                  <div className="row" style={{ textAlign: "center" }}>
                    <h5>Like us on:</h5>
                    <SocialMediaIcons color="themed" type="square" size={48} />
                  </div>
                  <div className="row">&nbsp;</div>
                  <GoogleAdContainer position="bottom" />
                  <div className="col-md-12">&nbsp;</div>
                  {/* divider */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="solidline"></div>
                    </div>
                  </div>
                  {/* end divider */}
                  <ReactWidgetPackComments id={22435} />
                </div>
                <div className="col-md-2">
                  <Right category={header.category} title={header.title} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

Article.propTypes = {
  header: PropTypes.object,
  data: PropTypes.array,
  history: PropTypes.object
};

export default withRouter(Article);
