import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["angularRouterConfigCLIApp"],
  ...allHashTags,
  channel: "17"
};

const data = [
  {
    type: "subheading",
    name: "angular-cli-app-tutorial",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Configuring Routing on Angular applications is probably the easiest things to do. " +
      "All you have to do is select the &quot;Angular Routing&quot; option while creating the scaffolding application using angular CLI. " +
      "But, wait!! if its so easy why all this discussion ? While installing the routing is easy in angular, creating two routes and making it work is not so easy. " +
      "We will try to make this process easy. So follow along with this easy to understand " +
      "Step by Step tutorial." +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Without wasting much time, lets create two routes, /dashboard and /user. " +
      "Once we have configured the setup correctly, we will test these routes to validate." +
      "<div class='lineBreak' /> " +
      "First, create a simple scaffolding angular application using the command: " +
      "<div class='lineBreakSmall' /> " +
      "<code style='white-space: normal'>ng new angular-routing</code>" +
      "<div class='lineBreak' /> " +
      "When prompted whether to add Angular Routing option, enter y and make sure routing is configured. This will create a bunch of files to get you started. We will modify some files and add few others. Follow along, here are the steps: "
  },
  {
    type: "text",
    content:
      "<h4>1. Create Routes and create two pages for testing routing setup :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h5>a. Create app.routing.module.ts :</h4>"
  },
  {
    type: "gist",
    content: "5513868c3ed8d4e1b0932ef997e89379"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is the most critical component of the entire routing. " +
      "If you try to use dashboard and user routes directly it might not work. The idea is simple. " +
      "We just have to create two container components for these two pages and render the routes inside it. " +
      "AppComponent and HomeComponents are the containers components here. Finally redirect everything else to /dashboard." +
      "<div class='lineBreak' /> "
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>b. Create dashboard.component.html using command: <code style='white-space: normal'>ng generate component dashboard</code></h4>"
  },
  {
    type: "gist",
    content: "1072a93f3ecae78b0be2ef84d66fbf23"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>c. Create user.component.html using command: <code style='white-space: normal'>ng generate component user</code></h4>"
  },
  {
    type: "gist",
    content: "5b03e503b6fe7e27a0239ec6bb9df098"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>d. Create home.component.html using command: <code style='white-space: normal'>ng generate component home</code></h4>"
  },
  {
    type: "gist",
    content: "bc8aca63233d44c879c31a52f8247f3b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h5>e. Update existing app.component.html :</h4>"
  },
  {
    type: "gist",
    content: "bc8aca63233d44c879c31a52f8247f3b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Existing app.module.ts should look like below :</h4>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "gist",
    content: "cf4066987c97e095a2b160f9889b6961"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Thats all !! Now start your application using <code>npm start</code> command." +
      "<div class='lineBreakSmall' /> " +
      "Navigate to <code>http://localhost:4200/</code>, if eveything is setup properly, you " +
      "should see a page similar to the below screen. The Dashboard screen will appear by default as it is configured as " +
      "our default route."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/angular-route-dashboard.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "When you click on the <code>User</code> link, you should navigate to the User screen. " +
      "Again on User screen, when you click on <code>Dashboard</code> link you should be taken back to Dashboard. " +
      "This confirms that our client side routing is working fine as expected." +
      "<div class='lineBreakSmall' /> " +
      "For a full working application, refer to the &quot;Download Source&quot; section at the end of this tutorial."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/angular-route-user.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Alright everything is working fine. But wait !! This is working fine only in your " +
      "development environment as we have setup only client side routing configuration. " +
      "<div class='lineBreak' /> " +
      "To make sure the application runs fine on production too, you need to do some configuration " +
      "on your server side as well. Refer to this tutorial for the complete configuration for your favorite server : " +
      "<MyLink class='homelink' href='/angular-router-config-apache-nginx-tomcat' target='_blank'><span>Angular Router Configuration - Apache Http Server, Nginx, Tomcat</span></MyLink>"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-router-apache-nginx-tomcat/",
    previousText:
      "React Router Configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/angular-router-config-apache-nginx-tomcat/",
    nextText: "Angular Router Configuration - Apache Http Server, Nginx, Tomcat"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/angular-routing.zip"
  }
];

class AngularRoutingConfigCLIApp extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default AngularRoutingConfigCLIApp;
