import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["jaxwsClientUsingCXF"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "10"
};

const data = [
  {
    type: "subheading",
    name: "jaxws-client-using-cxf",
    title: "Client"
  },
  {
    type: "text",
    content:
      "In our <MyLink class='homelink' href='/jaxws-soap-webservice-using-cxf' target='_blank'>JAX-WS webservice using CXF</MyLink> " +
      "tutorial, we have created a SOAP based webservice that contains single method <code>sayHi()</code>." +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Remember, in our test case, HelloAngryWorldImplTest.java, by default, there was one method that tests the webservice as below: " +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>1. HelloAngryWorldTest.java :</h4>"
  },
  {
    type: "gist",
    content: "c9c5ed8f0d09d0825e02d3879ed1f9d9"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The client code looks straightforward. Just create the implementation class object and directly call the required method."
  },
  {
    type: "subheading",
    name: "testing-the-webservice",
    title: "Testing the webservice:"
  },
  {
    type: "text",
    content:
      "There are number of ways you can test this SOAP webservice. Before running any of the below, make sure the tomcat server is started and the webservice is running using," +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean package -DskipTests=true tomcat7:run</code> or <code>mvn clean package -DskipTests=true jetty:run</code>"
  },
  {
    type: "text",
    content:
      "<h4>1. Using maven command :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn test</code>"
  },
  {
    type: "output",
    content:
      "\r-------------------------------------------------------" +
      "\rT E S T S" +
      "\r-------------------------------------------------------" +
      "\rRunning com.learninjava.HelloAngryWorldImplTest" +
      "\rResponse from web service is : Hello Angry World" +
      "\rTests run: 1, Failures: 0, Errors: 0, Skipped: 0, Time elapsed: 0.508 sec\r" +
      "\rResults :\r" +
      "\rTests run: 1, Failures: 0, Errors: 0, Skipped: 0\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "<h4>2. Using Poster plugin :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "Poster is a plugin for firefox browser. This is a very useful and handy tool to test your SOAP webservices quickly." +
      "<div class='lineBreak' /> " +
      "i. To test the sayHi method of HelloAngryWorld, enter the following in Poster UI :" +
      "<div class='lineBreak' /> " +
      "<b>URL</b>: http://localhost:<port-number>/jaxws-service/HelloAngryWorld?wsdl" +
      "<div class='lineBreakSmall' /> " +
      "<b>Content Type</b>:   application/xml" +
      "<div class='lineBreakSmall' /> " +
      "<b>Payload(Text Box at bottom)</b>:"
  },
  {
    type: "gist",
    content: "97cda9e26ec5511d6250ce13303521ab"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "and click on POST button. The response is displayed in the Response window."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-poster.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>3. Using Wizdler plugin :</h4>" +
      "Wizdler is a plugin for Chrome browser. wizdler plugin can be used to generate the sample request instead of using the heavyweight SoapUI." +
      "<div class='lineBreakSmall' /> " +
      "i. To test the sayHi method of HelloAngryWorld, enter the following URL in Chrome browsers address bar :" +
      "<div class='lineBreakSmall' /> " +
      "<b>URL</b>: http://localhost:<port-number>/jaxws-service/HelloAngryWorld?wsdl" +
      "<div class='lineBreakSmall' /> " +
      "You will see a small icon to the right corner of the address bar. See highlighted area in the screenshot below."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-wizdler1.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Click on the icon to get a list of all the methods in the WSDL. Since we have only one method, click on it <code>sayHi()</code>. You will get a sample SOAP request as shown."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-wizdler2.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Edit the request and replace [string?] with &quot;Angry World&quot;, without quotes and click Go button. The response from webservice will be displayed as shown below:"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-wizdler3.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You can click on &quot;Request&quot; and &quot;Response&quot; buttons at the bottom of the screen to navigate between request and responses."
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "navLinks",
    previousURL: "/jaxws-soap-webservice-using-cxf/",
    previousText: "JAX-WS SOAP WebService using CXF",
    nextURL: "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    nextText:
      "3 ways to convert SOAPMessage to Java Object with namespace and XML to SOAPMessage"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/cxfjaxws.zip"
  }
];

class SOAPClientUsingCXF extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default SOAPClientUsingCXF;
