import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactLoadingSpinnerWithOverlay"],
  ...allHashTags,
  channel: "22"
};

const data = [
  {
    type: "subheading",
    name: "react-loading-spinner-with-overlay",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Often, we want to show a loading indicator while fetching our data or while data is being loaded by our component." +
      "This way the user interface is more interactive and user is aware of what is happening. " +
      "In this article, we will see how to create a loading indicator. We will use a spinner icon from " +
      "<code>font-awesome</code> component library. Let's call it a loading spinner component. " +
      "First let's create a basic loading spinner later we will create a loading spinner with an overlay." +
      "<div class='lineBreakSmall' /> " +
      "To save time, we will use the app that we created before, that we created as part of " +
      "<MyLink class='homelink' href='/create-react-app-react-router' target='_blank'>" +
      "<span>React Router Configuration - Create React App - Step by Step Configuration</span></MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Go to the <code>Download Source</code> section of the above tutorial and once you get access to the source, follow the below steps, " +
      "as we will be modifying some of the files in that app."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "install-font-awesome",
    title: "Install font-awesome"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Open command prompt, navigate to the application root folder and type," +
      "<div class='lineBreak' /> " +
      "<code>npm install font-awesome</code>" +
      "<div class='lineBreakSmall' /> " +
      "We will use the spinner icon from this library, you can use any other if you want."
  },
  {
    type: "subheading",
    name: "install-classnames",
    title: "Install classnames"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Type below command while in command promt," +
      "<div class='lineBreak' /> " +
      "<code>npm install classnames</code>" +
      "<div class='lineBreak' /> " +
      "This library allows us to conditionally join and manipulate CSS classNames."
  },
  {
    type: "subheading",
    name: "create-loading-spinner",
    title: "Create LoadingSpinner component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a new file named <code>LoadingSpinner.js</code> and add the code as below."
  },
  {
    type: "gist",
    content: "7cadbdd8c64c6bb37ab23e450f15cae7"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let's see the importants aspects of the above code snippet:" +
      "<div class='lineBreakSmall' /> " +
      "1. Imported <code>font-awesome</code> and used its <code>fa-spinner</code> icon" +
      "<div class='lineBreakSmall' /> " +
      "2. Used <code>classnames</code> library to make it active conditionally using props.loading property"
  },
  {
    type: "subheading",
    name: "create-spinner",
    title: "Rename Dashboard.js to Spinner.js and call LoadingSpinner component"
  },
  {
    type: "gist",
    content: "d1d6174bf4ac3a6227765cc8ca5b6559"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "At this point, navigate to <code>http://localhost:3000/react-loading-spinner/</code> " +
      "and you should see a loading spinner similar to the below screenshot,"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-loading-spinner1.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "create-spinner-with-overlay",
    title: "Create Spinner with Overlay"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "If you are new to Overlays, It is just a grayed out area that appears over text, image or any content. " +
      "This is used basically to tell the user that the content is not accessible until the loading is complete. Now, " +
      "lets create an overlay and add a spinner on top of it."
  },
  {
    type: "subheading",
    name: "install-react-loading-overlay",
    title: "Install react-loading-overlay"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Open command prompt, navigate to the application root folder and type," +
      "<div class='lineBreak' /> " +
      "<code>npm install react-loading-overlay</code>" +
      "<div class='lineBreakSmall' /> " +
      "We will use this ready to use component for our overlay."
  },
  {
    type: "subheading",
    name: "create-overlay-component",
    title: "Create Reusable Overlay component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a resuable overlay component as below so that we can use it anywhere in our application."
  },
  {
    type: "gist",
    content: "c79d306f1b22768ef4e2369fd4703a5c"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Our reusable component takes in the loading spinner and automatically shows it when required. The " +
      "rest is self explanatory."
  },
  {
    type: "subheading",
    name: "call-overlay-component",
    title: "Rename User.js to SpinnerWithOverlay.js and call Overlay Component"
  },
  {
    type: "gist",
    content: "3ff64691889c3d623dd4d16989f5d31f"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Lets see what we added above," +
      "<div class='lineBreakSmall' /> " +
      "1. This is similar to Spinner.js except that we used Overlay component" +
      "<div class='lineBreakSmall' /> " +
      "2. Added a state property named <code>showOverlay</code> and a method named <code>handleShowOverlay()</code> to disable the overlay. " +
      "Also, added a method, <code>autoshowOverlay()</code> in <code>componentDidMount</code> call back to automatically stop the timer after 5 seconds." +
      "<div class='lineBreakSmall' /> " +
      "3. Use the <code>active</code> property to show or hide the spinner and overlay" +
      "<div class='lineBreakSmall' /> " +
      "4. Finally, added some text to show our components on top of it"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "At this point, navigate to <code>http://localhost:3000/react-loading-spinner/spinner-with-overlay</code> " +
      "and you should see a loading spinner along with an overlay similar to the below screenshot. The spinner and " +
      "overlay appears for 5 seconds and then both of them disappear to enable the text again for selection."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-loading-spinner2.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-proxying-api-backend-apache-nginx-tomcat/",
    previousText:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/how-to-create-react-table-with-pagination-and-sorting/",
    nextText:
      "React Table - How to create a simple react table with pagination and sorting"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-loading-spinner.zip"
  }
];

class ReactLoadingSpinnerWithOverlay extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactLoadingSpinnerWithOverlay;
