const commonMetaTags = {
  meta: [
    {
      type: "og:site_name",
      content: "Learninjava"
    },
    {
      type: "og:type",
      content: "article"
    },
    {
      type: "fb:app_id",
      content: "247313239759648"
    },
    {
      type: "twitter:card",
      content: "summary"
    },
    {
      type: "twitter:creator",
      content: "@learninjava"
    },
    {
      type: "twitter:domain",
      content: "learninjava.com"
    }
  ]
};

export default commonMetaTags;
