import React from "react";

const SpinningLogo = () => (
  <img
    className="logoHover"
    src="img/logo.png"
    height="24px"
    width="24px"
    alt="learninjava"
  />
);

export default SpinningLogo;
