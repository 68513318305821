import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["tlsMutualAuth"],
  ...allHashTags,
  channel: "30"
};

const data = [
  {
    type: "toc",
    title: "SSL Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/easy-guide-to-ssl' target='_blank'>" +
      "Easy Guide to SSL - All the terms you need to know</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/convert-p12-to-jks-and-pem-to-jks' target='_blank'>" +
      "Convert PKCS#12 to JKS and PEM to JKS format</MyLink></h4>" +
      "<h4><b>Part 3 : </b> Two way SSL/Mutual Authentication - How to use client " +
      "certificate in Postman and SOAP UI - [This article]</h4>"
  },
  {
    type: "subheading",
    name: "introduction",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Often, on most of the websites, the client validates the servers CA certificate to see if it can " +
      "be trusted or not. This is called one-way SSL(Secure Socket Layer) authentication. To secure content, some servers includes " +
      "additional security by asking the client to provide a certificate that the server understands. " +
      "This method is called two-way SSL authentication, also called as TLS(Transport Layer Security) Mutual Authentication." +
      "<div class='lineBreak' /> " +
      "We will see how this can be configured in both <code style='white-space: normal'>POSTMAN</code> and <code style='white-space: normal'>SOAP UI</code> tools using a practical realworld working " +
      "certificate against a realworld website, no more sample certificates that dont work. If you are new to the SSL terminology, we recommended you to quickly " +
      "go through this article, <MyLink class='homelink' href='/easy-guide-to-ssl' target='_blank'>" +
      "Easy Guide to SSL - All the terms you need to know</MyLink> and come back here. " +
      "<div class='lineBreak' /> " +
      "If you want to know how to convert between different store formats like " +
      "PKCS#12 or PEM to JKS, refer to this article, <MyLink class='homelink' href='/convert-p12-to-jks-and-pem-to-jks' target='_blank'>" +
      "Convert PKCS#12 to JKS and PEM to JKS format</MyLink>. We will use the JKS store created in this article."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "client-certificates-in-postman",
    title: "Client Certificate configuration in POSTMAN"
  },
  {
    type: "text",
    content:
      "Open POSTMAN and create a GET request with URL, https://client.badssl.com/ " +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>Enable SSL certificate verification</code> in " +
      "<code style='white-space: normal'>Settings</code>. Now, hit <code style='white-space: normal'>Send</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/postman-client-certificates-400.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The request fails with a 400 error as this needs a client certificate. Let's see how " +
      "we can fix this in mulitple different ways using Client Certificates."
  },
  {
    type: "text",
    content: "<h3>1. Using individual Certificate and Private Key</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Download the zip file from the <code style='white-space: normal'>Download Source</code> section " +
      "at the end of this article and use the Certificate and Private Key files in the POSTMAN settings as shown."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/certificate-and-privatekey.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Now, test your configuration, You should see now that the request " +
      "is successful!! You can also see the certificate being sent to the server in the POSTMAN console."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/postman-client-certificates-200.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>2. Using combined PKCS#12(.pfx/.p12) file</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Use the <code style='white-space: normal'>cert-and-key.p12</code> file from the downloaded archive and provide " +
      "as PFX file."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/cert-and-key-combined.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Test your configuration, You should see that the request " +
      "is successful again. Also, observe that the console now shows the combined Certificate and Private Key .p12 file."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/cert-and-key-combined-200.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "client-certificates-in-soapui",
    title: "Client Certificate configuration in SOAP UI"
  },
  {
    type: "text",
    content:
      "Open SOAPUI and create a GET request with URL, https://client.badssl.com/ and submit."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/soapui-client-certificates-400.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The request fails with a 400 error without a client certificate. Let's see how " +
      "we can fix this in mulitple different ways using Client Certificates."
  },
  {
    type: "text",
    content:
      "SOAP UI supports two different file formats, PKCS#12(.p12) and " +
      "Java KeyStore Format(.jks). In JKS format, again it supports PKCS#12 and JKS store types." +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h3>1. Using PKCS#12(.p12) file</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Use the <code style='white-space: normal'>badssl.com-client-pem.jks</code> " +
      "file from the PEM folder in <code style='white-space: normal'>Preferences > SSL Settings > KeyStore</code> and check <code style='white-space: normal'>requires client authentication</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/soapui-client-certificate-p12.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Now, test your configuration, You should see the request " +
      "is successful!! You can also see the certificates being exchanged with the server in the <code style='white-space: normal'>SSL Info</code>."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/soapui-client-certificates-200.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>2. Using JKS(.jks) file with JKS store type</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Use the <code style='white-space: normal'>badssl.com-client-jks.jks</code> " +
      "file from the PKCS folder in <code style='white-space: normal'>Preferences > SSL Settings > KeyStore</code> and check <code style='white-space: normal'>requires client authentication</code>" +
      "<div class='lineBreak' />Test your configuration and the request should be successful!!"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/soapui-client-certificates-200.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>3. Using JKS(.jks) file with PKCS store type</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Use the <code style='white-space: normal'>badssl.com-client-p12.jks</code> " +
      "file from the PKCS folder in <code style='white-space: normal'>Preferences > SSL Settings > KeyStore</code> and check <code style='white-space: normal'>requires client authentication</code>" +
      "<div class='lineBreak' />Test your configuration and the request should again be successful!!"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/soapui-client-certificates-200.jpg"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Bonus</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Since JKS file can contain both JKS and PKCS store types, " +
      "it is difficult to distinguish between these two files. Use the below commands to find the difference."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'> keytool -list -v -keystore badssl.com-client-jks.jks | grep 'Keystore type'</code>"
  },
  {
    type: "output",
    content:
      "\r$ keytool -list -v -keystore badssl.com-client-jks.jks | grep 'Keystore type'" +
      "\rEnter keystore password:  badssl.com:" +
      "\rKeystore type: jks\r" +
      "\rWarning:\r" +
      "\rThe JKS keystore uses a proprietary format. It is recommended to migrate to PKCS12 " +
      "which is an industry standard format using &quot;keytool -importkeystore -srckeystore " +
      "badssl.com-client-jks.jks -destkeystore badssl.com-client-jks.jks -deststoretype pkcs12&quot;."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>keytool -list -v -keystore badssl.com-client-p12.jks | grep 'Keystore type'</code>"
  },
  {
    type: "output",
    content:
      "\r$ keytool -list -v -keystore badssl.com-client-p12.jks | grep 'Keystore type'" +
      "\rEnter keystore password:  badssl.com:" +
      "\rKeystore type: PKCS12\r"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "The warning shown for the JKS store type is another indication that the file is JKS store type format."
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "subheading",
    name: "conclusion",
    title: "Conclusion"
  },
  {
    type: "text",
    content:
      "Client Certificates are required for mutual authentication. While POSTMAN supports individual certificate and key files " +
      "or a combined files, SOAPUI on the other hand supports JKS and PKCS#12 format stores only which means it always needs a store."
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/easy-guide-to-ssl/",
    previousText: "Easy Guide to SSL - All the terms you need to know",
    nextURL: "/convert-p12-to-jks-and-pem-to-jks/",
    nextText: "Convert PKCS#12 to JKS and PEM to JKS format"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/pkcs-pem-jks.zip"
  }
];

class TLsMutualAuth extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default TLsMutualAuth;
